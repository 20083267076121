<template>
  <div
    class="clipping-forward-select-duration-oparea-box fn-inline"
    :class="{
      [`clipping-forward-select-duration-oparea-box--${createRenderType}`]: true,
    }"
    ref="refSelectBox"
    @click="handleSelectBoxClick"
    :style="{
      '--fromTransLeft': `${renderData.fromTransLeft}px`,
      '--toTransRight': `${
        renderData.loading
          ? 0
          : renderData.renderBoxSize.width - renderData.toTransLeft
      }px`,
    }"
  >
    <div class="clipping-forward-select-duration-oparea-mask"></div>
    <div class="clipping-forward-select-duration-oparea">
      <div
        class="clipping-forward-select-duration-waves-loading"
        v-if="renderData.loading"
      >
        <template v-for="(item, index) in 8" :key="index">
          <div
            class="clipping-forward-select-duration-waves-loading-wave-bar block fn-inline"
          ></div>
        </template>
      </div>
      <div
        class="clipping-forward-select-duration-waves-box"
        v-if="!renderData.loading && renderData.ifRenderWavebar"
      >
        <AudioWaveRender
          :renderBoxSize="renderData.renderBoxSize"
          :config="renderPlayerData.config"
          :waveBarData="renderData.renderWavebarData"
          :activeIndex="renderData.activeIndex"
          :slecteAreaIndex="{
            startIndex: renderData.selectStartIndex,
            endIndex: renderData.selectEndIndex,
          }"
        ></AudioWaveRender>
      </div>
    </div>
    <div
      class="clipping-forward-select-duration-position-box"
      :style="{
        '--positionLeft': `${positionLeft}px`,
      }"
    >
      <div
        class="clipping-forward-select-duration-position-progress-hover"
        ref="refProgress"
      ></div>
      <div
        class="clipping-forward-select-duration-position-curTime"
        :class="{
          'clipping-forward-select-duration-position-curTime-visible':
            renderData.touchSwipeState &&
            renderData.touchSwipeStateElType == 'progress',
        }"
      >
        {{ mediaUtil.formatTimeMs(renderData.progress * renderData.duration) }}
      </div>
    </div>
    <div
      class="clipping-forward-select-duration-oparea-op-box"
      :class="{
        'clipping-forward-select-time-show': renderData.touchSwipeState,
      }"
    >
      <div
        class="clipping-forward-select-duration-oparea-op-area"
        ref="refDragSelectArea"
      ></div>
      <div
        class="clipping-forward-select-duration-op-start"
        :class="{
          'start-time-show': renderData.touchSwipeStateElType == 'from',
        }"
      >
        <div
          class="clipping-forward-select-duration-op-se pointer"
          ref="refDragSelectStart"
        >
          <i class="fn-inline"> </i>
          <i class="fn-inline c"> </i>
          <i class="fn-inline"> </i>
          <div class="clipping-forward-select-duration-op-curtime">
            {{
              props.formatTime
                ? props.formatTime(renderData.regenerateStartTime)
                : mediaUtil.formatTimeMs(renderData.regenerateStartTime)
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div>
      {{
        `width:${renderPlayerData.waveBarBoxRenderWidth};;fromTransLeft:${renderData.fromTransLeft};;toTransLeft:${renderData.toTransLeft};;`
      }}
    </div> -->
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  computed,
  readonly,
  reactive,
  watch,
  defineExpose,
  onMounted,
  onUpdated,
  onUnmounted,
} from "vue";
import globleConfig, { chinaAppName } from "@/config/config.js";

import AudioWaveRender from "@/components/media/audioPlayer/AudioWaveRender.vue";
import LoaderProgressCircle from "@/components/animation/LoaderProgressCircle.vue";
import mediaUtil from "@/components/media/utils/utils.js";
import ffmpegUtil from "@/components/media/utils/ffmpegUtil.js";

import globalAudioPlayer from "@/components/media/audioPlayer/GlobalAudioPlayer.js";
import tools from "@/utils/tools.js";
import clipUtil from "@/components/media/utils/clipUtil.js";
import { $$language, $$t } from "@/i18n/i18n.js";

import { storeToRefs } from "pinia";
import useCreateLayoutStore from "@/store/create/createLayoutStore.js";
const createLayoutStore = useCreateLayoutStore();
const { createRenderType } = storeToRefs(createLayoutStore);

const defaultConfig = {
  spacingSize: 3,
  wavebarWidth: 1,
  barMaxheight: 30,
  minSelectDuration: 0,
  retentionPrefixDuration: 8,
  defaultColor: "#fff",
  progressColor: "#72FFEC", //"#72FFEC",
  selectAreaColor: "#fff",
};
const props = defineProps({
  config: {
    type: Object,
    default: () => {
      return {};
    },
  },
  formatTime: {
    type: Function,
    default: null,
  },
  url: {
    type: String,
    default: "",
  },
  duration: {
    type: Number,
    default: 0,
  },
  waveformData: {
    type: Array,
    default: () => {
      return [];
    },
  },
});
const emits = defineEmits([
  "syncPlayState",
  "syncProgress",
  "syncWaveLoadingState",
  "syncSelectData",
  "selectDataChange",
  "selectChangeLimit",
]);
const refSelectBox = ref(null);
const refDragSelectStart = ref(null);
const refProgress = ref(null);

const stateData = {};
const renderData = reactive({
  loading: false,
  renderBoxSize: {
    width: 0,
    height: 0,
  },

  isPlaying: false,
  progress: 0,
  duration: props.duration,
  startTime: 0,
  endTime: 0,

  activeIndex: 0,
  selectStartIndex: 0,
  selectEndIndex: 0,

  renderWavebarData: [],

  fromTransLeft: 0,
  toTransLeft: 0,
  progressTransLeft: 0,
  regenerateStartTime: 0,
  regenerateEndTime: props.duration,

  touchSwipeState: false,
  touchSwipeStateElType: null,
  ifRenderWavebar: true,
});
const renderPlayerData = reactive({
  decodeData: null,
  totalDuration: 0,
  minSelectAreaWidth: 0,
  retentionPrefixWidth: 0,
  retentionSuffixWidth: 0,
});

const positionLeft = computed(() => {
  // console.log("positionLeft", renderPlayerData.waveBarBoxRenderWidth);
  return Math.max(
    0,
    Math.min(
      renderData.progress * renderPlayerData.waveBarBoxRenderWidth,
      renderPlayerData.waveBarBoxRenderWidth - 1
    )
  );
});

const globalAudioPlayerData = {
  curPlayerData: null,
};

const initConfig = () => {
  renderPlayerData.config = Object.assign({}, defaultConfig, props.config);
};

const doTogglePlay = async () => {
  if (renderData.isPlaying) {
    globalAudioPlayer.pause(globalAudioPlayerData.curPlayerData?.url);
  } else {
    globalAudioPlayer.play(globalAudioPlayerData.curPlayerData?.url, false);
  }
};
const handleSelectBoxClick = (e) => {
  if (renderData.loading || renderData.touchSwipeState) {
    return;
  }
  const { width, left } = refSelectBox.value.getBoundingClientRect();
  const clientX = e.clientX;
  globalAudioPlayer.seek(
    globalAudioPlayerData.curPlayerData?.url,
    (clientX - left) / width
  );
};
const doSetPlayeData = async () => {
  // globalAudioPlayerData.clipDecodeData = await clipUtil.clipdDecodeData(stateData.decodeData, renderPlayerData.startTime, renderPlayerData.endTime)
  // const exportData = await clipUtil.decodeDataToUrl(stateData.decodeData);
  const url = props.url;
  if (globalAudioPlayerData.curPlayerData !== null) {
    globalAudioPlayer.releasePlayer(globalAudioPlayerData.curPlayerData);
  }

  if (stateData.mounted) {
    const { endClipTime } = renderPlayerData.config;
    globalAudioPlayerData.curPlayerData = globalAudioPlayer.registerPlayUrl(
      url,
      {
        personalizedConfig: {
          loop: false,
          autoToStartIfNotCurrent: false,
          canSeekNotCurrent: true,
        },
        duration: renderData.duration ?? 0,
        clipStart: 0,
        clipEnd: (renderData.duration ?? 0) - endClipTime,
        listeners: {
          onDurationChange({
            duration,
            durationTime,
            sourceDuration,
            sourceDurationTime,
          }) {
            renderData.duration = duration;
          },
          onPlayStateChange(isPlaying) {
            renderData.isPlaying = isPlaying;
          },
          onLoadingStateChange(isLoading) {},
          onPositionChange: (obj) => {
            const { progress, currentTime, endTime } = obj;
            renderData.startTime = currentTime;
            renderData.endTime = endTime;
            if (
              !(
                renderData.touchSwipeState &&
                renderData.touchSwipeStateElType == "progress"
              )
            ) {
              renderData.progress = progress;
              renderData.activeIndex = Math.ceil(
                progress * renderData.renderWavebarData.length
              );
            }
          },
        },
      }
    );
    // globalAudioPlayer.play(globalAudioPlayerData.curPlayerData?.url, false);
  }
};

const setRenderWaveData = async () => {
  const {
    spacingSize,
    wavebarWidth,
    minSelectDuration,
    endClipTime,
    barMaxheight,
  } = renderPlayerData.config;
  renderPlayerData.waveBarCount = Math.floor(
    (renderPlayerData.waveBarBoxRenderWidth + spacingSize) /
      (spacingSize + wavebarWidth)
  );
  let wavebarData = [];
  if (props.waveformData?.length) {
    wavebarData = clipUtil.getWaveDataByWaveFormData(
      props.waveformData,
      renderPlayerData.waveBarCount
    );
  } else {
    if (props.url) {
      try {
        wavebarData = await clipUtil.getWaveDataByUrl(
          props.url,
          renderPlayerData.waveBarCount,
          endClipTime
        );
      } catch (e) {
        wavebarData = Array.from(
          { length: renderPlayerData.waveBarCount },
          () => Math.random()
        );
      }
    } else {
      wavebarData = Array.from({ length: renderPlayerData.waveBarCount }, () =>
        Math.random()
      );
    }
  }
  const wavebarPainterData = clipUtil.fixedPaintHarfHeightWaveBarData(
    wavebarData,
    barMaxheight
  );
  renderData.renderWavebarData = wavebarPainterData;
};

const setRenderSelectData = async () => {
  const {
    spacingSize,
    wavebarWidth,
    minSelectDuration,
    retentionPrefixDuration,
  } = renderPlayerData.config;
  const width = renderPlayerData.waveBarBoxRenderWidth;
  const duration = renderData.duration;

  renderData.fromTransLeft = width;
  renderPlayerData.retentionPrefixWidth =
    (retentionPrefixDuration / duration) * width;

  renderData.regenerateStartTime = Math.max(
    0,
    Math.min((renderData.fromTransLeft / width) * duration, renderData.duration)
  );
  renderData.selectStartIndex = Math.floor(
    (renderData.fromTransLeft / width) * renderData.renderWavebarData.length
  );

  renderData.regenerateEndTime = renderData.duration;

  renderData.toTransLeft = width;
};

const doSetRegenerateData = (isEnd) => {
  const {
    spacingSize,
    wavebarWidth,
    minSelectDuration,
    retentionPrefixDuration,
  } = renderPlayerData.config;
  const width = renderPlayerData.waveBarBoxRenderWidth;

  renderData.selectStartIndex = Math.floor(
    (renderData.fromTransLeft / width) * renderData.renderWavebarData.length
  );
  renderData.selectEndIndex = Math.ceil(
    (renderData.toTransLeft / width) * renderData.renderWavebarData.length
  );
  renderData.regenerateStartTime = Math.max(
    0,
    Math.min(
      renderData.duration,
      Math.max(
        retentionPrefixDuration,
        (renderData.fromTransLeft / width) * renderData.duration
      )
    )
  );
  renderData.regenerateEndTime = Math.max(
    0,
    Math.min(
      renderData.duration,
      (renderData.toTransLeft / width) * renderData.duration
    )
  );

  if (isEnd) {
    // if (!renderData.isPlaying) {
    //   globalAudioPlayer.play(
    //     globalAudioPlayerData.curPlayerData?.url,
    //     true,
    //     renderData.regenerateStartTime
    //   );
    // } else {
    globalAudioPlayer.seek(
      globalAudioPlayerData.curPlayerData?.url,
      renderData.regenerateStartTime / renderData.duration
    );
    // }
  }
};

const syncSelectData = () => {
  emits("syncSelectData", {
    duration: renderData.duration,
    from: renderData.regenerateStartTime,
    to: renderData.regenerateEndTime,
  });
};

const onSelectDataChange = () => {
  emits("selectDataChange", {
    duration: renderData.duration,
    from: renderData.regenerateStartTime,
    to: renderData.regenerateEndTime,
  });
};

const doChangeProgress = (progress) => {
  globalAudioPlayer.seek(globalAudioPlayerData.curPlayerData?.url, progress);
};

const doSelectChangeLimit = (data) => {
  emits("selectChangeLimit", data);
};

const initEvent = () => {
  renderData.touchSwipeState = false;

  let fromTransLeft = 0;
  let toTransLeft = 0;
  let selectAreaWidth = 0;

  stateData.registTouchWaveBarSwipeStartCall = tools.registTouchSwipe(
    refDragSelectStart.value,
    {
      direction: "horizontal",
      startCb: (e, actionData) => {
        if (!renderData.touchSwipeState) {
          renderData.touchSwipeStateElType = "from";
          renderData.touchSwipeState = true;
          fromTransLeft = renderData.fromTransLeft;
          console.log(
            "start",
            fromTransLeft,
            renderPlayerData.waveBarBoxInfo?.width
          );
        }
      },
      moveCb: (e, actionData) => {
        const { startX, endX } = actionData;
        const dx = Math.floor(endX - startX);
        const { width } = renderPlayerData.waveBarBoxInfo;
        const { retentionPrefixWidth, minSelectAreaWidth } = renderPlayerData;

        let newFromTransLeft = fromTransLeft + dx;

        renderData.fromTransLeft = Math.max(
          retentionPrefixWidth,
          Math.min(newFromTransLeft, width)
        );

        doSetRegenerateData();
        onSelectDataChange();
        if (newFromTransLeft < renderData.fromTransLeft) {
          doSelectChangeLimit("min");
        }
      },
      endCb: (e, actionData) => {
        if (renderData.touchSwipeState) {
          setTimeout(() => {
            renderData.touchSwipeState = false;
          }, 0);
          doSetRegenerateData(true);
          syncSelectData();
        }
      },
    }
  );

  let progressTransLeft = 0;

  stateData.registTouchWaveBarSwipeProgressCall = tools.registTouchSwipe(
    refProgress.value,
    {
      direction: "horizontal",
      startCb: (e, actionData) => {
        if (!renderData.touchSwipeState) {
          renderData.touchSwipeState = true;
          renderData.touchSwipeStateElType = "progress";
          progressTransLeft = positionLeft.value;
        }
      },
      moveCb: (e, actionData) => {
        const { startX, endX } = actionData;
        const dx = Math.floor(endX - startX);
        const width = renderPlayerData.waveBarBoxRenderWidth;
        let newTransLeft = progressTransLeft + dx;
        newTransLeft = Math.max(0, Math.min(newTransLeft, width));

        renderData.progress = newTransLeft / width;
      },
      endCb: (e, actionData) => {
        if (renderData.touchSwipeState) {
          setTimeout(() => {
            renderData.touchSwipeState = false;
          }, 10);
          doChangeProgress(renderData.progress);
        }
      },
    }
  );
};

// 初始化操作
const init = (url) => {
  return new Promise((resolve, reject) => {
    try {
      renderData.loading = true;
      nextTick(async () => {
        renderPlayerData.waveBarBoxInfo =
          refSelectBox.value.getBoundingClientRect();
        renderData.renderBoxSize = {
          width: renderPlayerData.waveBarBoxInfo.width,
          height: renderPlayerData.waveBarBoxInfo.height,
        };
        renderPlayerData.waveBarBoxRenderWidth =
          renderPlayerData.waveBarBoxInfo.width;

        renderData.duration = props.duration;
        await setRenderWaveData();
        await setRenderSelectData();
        syncSelectData();
        nextTick(async () => {
          if (!stateData.mounted) return;
          await doSetPlayeData();
          stateData.renderWavebarReady = true;
          renderData.loading = false;
          // doTogglePlay();
          resolve();
        });
      });
    } catch (e) {
      reject(e);
    }
  });
};

const recalcAndRender = async () => {
  if (renderData.loading) {
    init(props.url);
  } else {
    renderPlayerData.waveBarBoxInfo =
      refSelectBox.value.getBoundingClientRect();
    renderData.renderBoxSize = {
      width: renderPlayerData.waveBarBoxInfo.width,
      height: renderPlayerData.waveBarBoxInfo.height,
    };
    renderPlayerData.waveBarBoxRenderWidth =
      renderPlayerData.waveBarBoxInfo.width;
    await setRenderWaveData();
    const {
      spacingSize,
      wavebarWidth,
      minSelectDuration,
      retentionPrefixDuration,
    } = renderPlayerData.config;
    const width = renderPlayerData.waveBarBoxRenderWidth;
    const duration = renderData.duration;

    renderPlayerData.retentionPrefixWidth =
      (retentionPrefixDuration / duration) * width;

    renderData.fromTransLeft = Math.max(
      renderPlayerData.retentionPrefixWidth,
      (renderData.regenerateStartTime / duration) * width
    );
    renderData.selectStartIndex = Math.floor(
      (renderData.fromTransLeft / width) * renderData.renderWavebarData.length
    );
    renderData.regenerateEndTime = renderData.duration;
    renderData.toTransLeft = width;
  }
  renderData.ifRenderWavebar = false;
  nextTick(() => {
    renderData.ifRenderWavebar = true;
  });
};

watch(
  () => renderData.isPlaying,
  (newValue) => {
    emits("syncPlayState", renderData.isPlaying);
  }
);
watch(
  () => renderData.progress,
  (newValue) => {
    if (
      !(
        renderData.touchSwipeState &&
        renderData.touchSwipeStateElType == "progress"
      )
    ) {
      emits("syncProgress", renderData.progress, renderData.startTime);
    }
  }
);
watch(
  () => renderData.duration,
  (newValue) => {
    emits("syncDuration", renderData.duration);
  }
);

watch(
  () => {
    return props.duration;
  },
  () => {
    renderData.duration = props.duration;
  }
);

watch(
  () => {
    return props.url;
  },
  () => {
    renderData.url = props.url;
    init(props.url);
  }
);

watch(
  () => renderData.loading,
  (newValue) => {
    emits("syncWaveLoadingState", renderData.loading);
  }
);

onMounted(() => {
  stateData.mounted = true;
  initConfig();
  init(props.url);
  initEvent();
  renderData.resizeObserver = new ResizeObserver(
    tools.debounce(() => {
      if(stateData.renderWavebarReady){
        recalcAndRender();
      }
    }),
    1000
  );

  renderData.resizeObserver?.observe(document.body);
});

onUnmounted(() => {
  stateData.registTouchWaveBarSwipeStartCall?.();
  stateData.registTouchWaveBarSwipeProgressCall?.();

  stateData.mounted = false;
  if (globalAudioPlayerData.curPlayerData) {
    globalAudioPlayer.pause(globalAudioPlayerData.curPlayerData?.url);
    globalAudioPlayer.releasePlayer(globalAudioPlayerData.curPlayerData);
  }
  renderData.resizeObserver?.disconnect();
});

defineExpose({
  togglePlay: doTogglePlay,
  getRenerateSelectTime: () => {
    return {
      from: renderData.regenerateStartTime,
      to: renderData.regenerateEndTime,
    };
  },
  setRenerateStartTime: (time) => {
    renderData.regenerateStartTime = time;
    renderData.fromTransLeft = Math.max(
      renderPlayerData.retentionPrefixWidth,
      (renderData.regenerateStartTime / duration) * width
    );
    renderData.selectStartIndex = Math.floor(
      (renderData.fromTransLeft / width) * renderData.renderWavebarData.length
    );
    renderData.ifRenderWavebar = false;
    nextTick(() => {
      renderData.ifRenderWavebar = true;
    });
  },
});
</script>
<style lang="scss">
@keyframes audio-waveform-bar-animation {
  0% {
    transform: scaleY(1);
    opacity: 1;
  }
  50% {
    transform: scaleY(5);
    opacity: 1;
  }
  100% {
    transform: scaleY(1);
    opacity: 1;
  }
}
.clipping-forward-select-duration-oparea-box {
  position: relative;
  width: 100%;
  --opAreaHeight: 88px;
  background: rgba(148, 173, 255, 0.6);
  .clipping-forward-select-duration-oparea-mask {
    position: absolute;
    top: 0;
    right: var(--toTransRight);
    left: var(--fromTransLeft);
    height: 100%;
    background: #553cbc;
  }
  .clipping-forward-select-duration-oparea {
    height: var(--opAreaHeight);
    position: relative;
  }
  .clipping-forward-select-duration-waves-loading {
    height: var(--opAreaHeight);
    width: 100%;
    text-align: center;
    line-height: var(--opAreaHeight);
    .clipping-forward-select-duration-waves-loading-wave-bar {
      width: 2px;
      height: 2px;
      background: #72ffec;
      margin: 0 5px;
      animation: audio-waveform-bar-animation 0.5s infinite;
      animation-timing-function: cubic-bezier(0.43, 0.04, 0.51, 0.94);
    }
    @for $i from 1 through 8 {
      .clipping-forward-select-duration-waves-loading-wave-bar:nth-child(
          #{$i}
        ) {
        animation-delay: #{($i - 1) * 0.05}s;
      }
    }
  }
  .clipping-forward-select-duration-waves-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--opAreaHeight);
  }
  .clipping-forward-select-duration-position-box {
    position: absolute;
    top: 0;
    height: 100%;
    width: 1px;
    background: #fff;
    left: var(--positionLeft);
    display: block;
    z-index: 1;

    .clipping-forward-select-duration-position-curTime {
      position: absolute;
      top: -41px;
      font-family: HarmonyOS Sans SC;
      font-size: 12px;
      font-weight: normal;
      line-height: 28px;
      color: #000;
      padding: 0 12px;
      border-radius: 28px;
      background: rgba(148, 173, 255, 1);
      display: none;
      width: max-content;
      transform: translateX(-50%);
      &:after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: -5px;
        margin-left: -5px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid rgba(148, 173, 255, 1);
      }
      &.clipping-forward-select-duration-position-curTime-visible {
        display: block;
      }
    }
    .clipping-forward-select-duration-position-progress-hover {
      position: absolute;
      width: 13px;
      height: 100%;
      background: transparent;
      left: -6px;
      top: 0;
      &:hover {
        background: rgba(255, 255, 255, 0.5);
        .clipping-forward-select-duration-position-curTime {
          display: block;
        }
      }
    }
  }
  .clipping-forward-select-duration-oparea-op-box {
    position: absolute;
    border-top: 2px solid #94adff;
    border-bottom: 2px solid #94adff;
    height: 100%;
    top: 0px;
    right: var(--toTransRight);
    left: var(--fromTransLeft);
    .clipping-forward-select-duration-oparea-op-area {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
    }
    .clipping-forward-select-duration-op-start {
      width: 0px;
      height: calc(100% + 4px);
      margin-top: -2px;
    }
    .clipping-forward-select-duration-op-se {
      background: #94adff;
      width: 12px;
      height: 100%;
      line-height: var(--opAreaHeight);
      text-align: center;
      position: absolute;
      i {
        width: 1px;
        height: 16px;
        background: #000000;
      }
      i.c {
        margin: 0 1px;
      }
    }

    .clipping-forward-select-duration-op-curtime {
      position: absolute;
      top: -41px;
      font-family: HarmonyOS Sans SC;
      font-size: 12px;
      font-weight: normal;
      line-height: 28px;
      color: #000;
      padding: 0 12px;
      border-radius: 28px;
      background: rgba(148, 173, 255, 1);
      display: none;
      width: max-content;
      &:after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: -5px;
        margin-left: -5px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid rgba(148, 173, 255, 1);
      }
    }
    .clipping-forward-select-duration-op-se:hover {
      .clipping-forward-select-duration-op-curtime {
        display: block;
      }
    }
    .clipping-forward-select-duration-op-start {
      position: absolute;
      left: 0;
      .clipping-forward-select-duration-op-se {
        border-radius: 6px 0 0 6px;
        right: 0;
      }
      .clipping-forward-select-duration-op-curtime {
        right: 0;
        transform: translateX(50%);
      }
    }
    &.clipping-forward-select-time-show {
      .start-time-show {
        .clipping-forward-select-duration-op-curtime {
          display: block;
        }
      }
    }
  }
  &.clipping-forward-select-duration-oparea-box--mobile {
    --opAreaHeight: 88px;
    background: rgba(148, 173, 255, 0.6);
    .clipping-forward-select-duration-oparea-op-box {
      border-top: 2px solid #94adff;
      border-bottom: 2px solid #94adff;
      .clipping-forward-select-duration-oparea-op-area {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
      }
      .clipping-forward-select-duration-op-start {
        width: 0px;
        height: calc(100% + 4px);
        margin-top: -2px;
      }
      .clipping-forward-select-duration-op-se {
        background: #94adff;
        width: 12px;
        i {
          width: 1px;
          height: 10px;
          background: #000000;
        }
      }
      .clipping-forward-select-duration-op-curtime {
        font-size: 10px;
        font-weight: 500;
        line-height: 20px;
        padding: 0 12px;
        top: -30px;
        &:after {
          bottom: -4px;
          margin-left: -4px;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 4px solid rgba(148, 173, 255, 1);
        }
      }
    }
  }
}
</style>
