import { defineStore, setActivePinia } from "pinia";
import { $$language, $$t } from "@/i18n/i18n.js";
import useUserDataStore from "@/store/userDataStore.js";
import layoutConfig from "@/config/layoutConfig";
import globleConfig from "@/config/config.js";
import feedListApi from "@/api/api/feedListApi.js";
import message from "@/components/functionCallComponent/message.jsx";
import useDraftDataStore from "./draftDataStore.js";

export const TabDataSuffixMap = {
  1: "all",
};

const MapEasyModeProsetDatApiMap = {
  // 1: feedListApi.getEasyModePresetData,
  1: feedListApi.getEasyModePresetDataV2,
};

const useBgmCreateEasyModeStore = defineStore({
  id: "bgmCreateEasyModeStore",
  state: () => {
    return {
      easyPromptData: {
        content: "",
      },
      datas_all: [],
      scenarios_all: [],
      scenariosPromptmaps_all: null,
      loadmoreid_all: "",
      isloading_all: false,
      hasInit_all: false,
      loadtimestamp_all: "",
      currentScenario: null,
      randomPromptData: null,
      // currentStyle: null,
    };
  },
  actions: {
    initLibraryData() {
      // this.datas_all = [];
      // this.scenarios_all = [];
      this.scenariosPromptmaps_all = null;
      this.loadmoreid_all = "";
      this.isloading_all = false;
      this.hasInit_all = false;
      this.loadtimestamp_all = "";
      this.currentScenario = null;
    },
    initEasyPromptData(data) {
      this.easyPromptData = data?.prompt ?? {
        content: "",
      };
     
      this.cacheLocalDraftData();
    },
    setEasyPromptData(data) {
      this.easyPromptData = data;
      this.cacheLocalDraftData();
    },
    setPromptDataContent(data) {
      this.easyPromptData = {
        content: data,
      };
      this.cacheLocalDraftData();
    },
    getEasyPromptData() {
      if (!this.easyPromptData?.content) {
        return {
          content: this.defaultPromptData?.content ?? "",
        };
      }
      return (
        this.easyPromptData ?? {
          content: "",
        }
      );
    },
    setDefaultPromptData(data) {
      this.defaultPromptData = {
        content: data,
      };
    },
    setCurrentScenario(data) {
      // this.currentStyle = data;
      this.currentScenario = data;
      this.cacheLocalDraftData();
    },

    cacheLocalDraftData() {
      const draftDataStore = useDraftDataStore();
      draftDataStore.cacheLocalDraftData()
    },

    getEasyModeData() {
      return {
        easyPromptData: this.easyPromptData,
        currentScenario: this.currentScenario,
        randomPromptData: this.randomPromptData,
      }
    },
    setEasyModeData(easyModeData) {
      this.easyPromptData = easyModeData.easyPromptData;
      this.currentScenario = easyModeData.currentScenario;
      this.randomPromptData = easyModeData.randomPromptData;
    },

    init(params, done, errordone, isSearch, showLoading) {
      const { query_type } = params;
      let closeMsg = null;
      return new Promise((resolve, reject) => {
        if (showLoading) {
          closeMsg = message.loading({
            position: "center",
            content: $$t("create.loading"),
          });
        }

        this[`isloading_${TabDataSuffixMap[query_type]}`] = true;
        this.getdatas(params, done, errordone, isSearch ?? true)
          .then((datas) => {
            this[`hasInit_${TabDataSuffixMap[query_type]}`] = true;
            this[`isloading_${TabDataSuffixMap[query_type]}`] = false;
            this[`hasInit${TabDataSuffixMap[query_type]}`] = false;
            resolve(datas);
            closeMsg && closeMsg.clear();
          })
          .catch((err) => {
            closeMsg && closeMsg.clear();
            this[`isloading_${TabDataSuffixMap[query_type]}`] = false;
            reject(err);
          });
      });
    },
    getdatas(params, done, errordone, isSearch) {
      const { query_type } = params;
      let loadtimestamp = new Date().getTime();
      this[`loadtimestamp_${TabDataSuffixMap[query_type]}`] = loadtimestamp;
      const getEasyModePresetData = MapEasyModeProsetDatApiMap[query_type];
      params.mode = 4
      return new Promise(async (resolve, reject) => {
        getEasyModePresetData(params)
          .then((res) => {
            const { data, code, msg } = res;
            if (code == 200) {
              if (
                !isSearch ||
                this[`loadtimestamp_${TabDataSuffixMap[query_type]}`] ==
                loadtimestamp
              ) {
                // this[`datas_${TabDataSuffixMap[query_type]}`] = data.list ?? [];
                // this[`loadmoreid_${TabDataSuffixMap[query_type]}`] =
                //   data.last_id;

                this[`scenarios_${TabDataSuffixMap[query_type]}`] = data.scenarios ?? [];
                this.currentScenario = this[`scenarios_${TabDataSuffixMap[query_type]}`][0];
                this[`scenariosPromptmaps_${TabDataSuffixMap[query_type]}`] = (data.prompts ?? []).reduce((res, cur) => {
                  res[cur.scenario] = cur.list;
                  return res
                }, {})

                // this[`datas_${TabDataSuffixMap[query_type]}`] = data.list ?? [];

                this[`loadmoreid_${TabDataSuffixMap[query_type]}`] = data.last_id;
              }
              done && done(data.list ?? [], !data.last_id);
              resolve(this[`datas_${TabDataSuffixMap[query_type]}`]);
            } else {
              errordone && errordone(code == -200);
              // if (code == -200) {
              //   message.error({position: "top", content: msg });
              // } else {
              //   message.error({position: "top", content: "加载失败" });
              // }
              reject(this[`datas_${TabDataSuffixMap[query_type]}`]);
            }
          })
          .catch((err) => {
            errordone && errordone();
            reject(err);
            //   message.error({position: "top", content: "加载失败" });
          });
      });
    },
    async refresh(params, done, errordone) {
      const { query_type } = params;
      if (this[`isloading_${TabDataSuffixMap[query_type]}`]) {
        done && done();
        return;
      }
      this[`isloading_${TabDataSuffixMap[query_type]}`] = true;
      this.getdatas(params, done, errordone)
        .then((res) => {
          this[`isloading_${TabDataSuffixMap[query_type]}`] = false;
        })
        .catch((e) => {
          this[`isloading_${TabDataSuffixMap[query_type]}`] = false;
        });
    },
    async loadMore(params, done, errordone) {
      const { query_type } = params;
      params.last_id = this[`loadmoreid_${TabDataSuffixMap[query_type]}`];
      if (this[`isloading_${TabDataSuffixMap[query_type]}`]) {
        done && done();
        return;
      }
      let scenarios = this.currentScenario
      params.scenario = scenarios
      if (!params.last_id) {
        done && done([], true);
        return;
      }
      this[`isloading_${TabDataSuffixMap[query_type]}`] = true;
      const getEasyModePresetData = MapEasyModeProsetDatApiMap[query_type];
      params.mode = 4
      getEasyModePresetData(params)
        .then((res) => {
          const { data, code, msg } = res;
          if (code == 200) {
            // this[`datas_${TabDataSuffixMap[query_type]}`] = this[
            //   `datas_${TabDataSuffixMap[query_type]}`
            // ].concat(data.list ?? []);
            this[`scenariosPromptmaps_${TabDataSuffixMap[query_type]}`] = this[`scenariosPromptmaps_${TabDataSuffixMap[query_type]}`][scenarios].concat(data.prompts[0]?.list ?? []);
            this[`loadmoreid_${TabDataSuffixMap[query_type]}`] = data.last_id;
          }
          //  else {
          //   message.error({ content: msg });
          // }

          nextTick(() => {
            if (code == 200) {
              done && done(data.list ?? [], !data.last_id);
            } else {
              errordone && errordone(code == -200);
            }
            this[`isloading_${TabDataSuffixMap[query_type]}`] = false;
          });
        })
        .catch((err) => {
          errordone && errordone(err);
        });
    },
    initEasyModePresetData(params) {
      return new Promise((resolve, reject) => {
        feedListApi.getEasyModePresetTypeData().then((res) => {
          const { data, code, msg } = res;
          if (code == 200) {

          }
        })
      })
    },


  },
});

export default useBgmCreateEasyModeStore;
