import axiosIS from '@/api/net/axios.js';
import { baseUrl } from '@/config/apiConfig.js';

// 模型列表
export function getModelList(data = {}) {
    return axiosIS(
      `${baseUrl}/api/pgc/model/list`,
      data,
      { method: 'get' }
    );
  }

// 查询用户训练任务
export function queryLoraTaskProcess(data = {}) {
    return axiosIS(
      `${baseUrl}/api/pgc/lora-task/process`,
      data,
      { method: 'get'}
    );
}

// 上传训练素材
export function addUploads(data = {}, { controller }) {
  return axiosIS(
    `${baseUrl}/api/pgc/uploads/add`,
    data,
    { method: 'post', controller}
  );
}

// 删除训练素材
export function delUploads(data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/uploads/del`,
    data,
    { method: 'post'}
  );
}

// 查询用户已上传列表
export function getUploadList(data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/uploads/list`,
    data,
    { method: 'post'}
  );
}

// 模型训练
export function createLora(data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/lora/create`,
    data,
    { method: 'post'}
  );
}

// 更新训练任务
export function updateLoraTask(data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/lora-task/update`,
    data,
    { method: 'post'}
  );
}

// 取消任务
export function cancelLoraTask(data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/lora-task/cancel`,
    data,
    { method: 'post'}
  );
}

// 轮询用户任务状态
export function getLoraTaskState(data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/lora-task/state`,
    data,
    { method: 'get'}
  );
}

// 模型选择上报
export function reportSelectModel(data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/model/select/report`,
    data,
    { method: 'post'}
  );
}