import { defineStore } from "pinia";
import { ref, nextTick } from "vue";
import tools from "@/utils/tools.js";
import globalConfig from "@/config/config.js";
import feedListApi from "@/api/api/feedListApi.js";
import sseGenerateApi from "@/api/api/apiSse.js";
import message from "@/components/functionCallComponent/message.jsx";
import { getObjectStorageFullUrl } from "@/lib/objectStorage/objectStorage.js";
import { $$t } from "@/i18n/i18n.js";

import ShowActionDialog from "@/components/functionCallComponent/action/action.js";
import useCreateEasyModeStore from "@/store/create/createEasyModeStore.js";
import useFeedListStore from "./feedListDataStore.js";
import useLyricDataStore from "./lyricDataStore.js";
import useMuseDataStore from "./museDataStore.js";
import useCreatVideoEditDataStore from "@/store/createVideo/creatVideoEditDataStore.js"
import useCreatVideoResultDataStore from "@/store/createVideo/creatVideoResultDataStore.js"
import useCreateLayoutStore from "@/store/create/createLayoutStore.js"
import useModelDataStore from "@/store/model/modelDataStore.js"


import useUserDataStore from "@/store/userDataStore"
import userReferMusicStore from "./referMusicStore.js";
import userVocalMusicStore from "./vocalMusicStore.js";
import userMotifDataStore from "./motifDataStore.js";
import usePromptDataStore from "./promptDataStore.js";

import useBgmCreateEasyModeStore from "@/store/create/bgmCreateEasyModeStore.js";
import useBgmReferMusicStore from "@/store/create/bgmReferMusicStore.js";

// import useDraftDataStore from './draftDataStore.js';
// const draftDataStore = useDraftDataStore();
// const lyricDataStore = useLyricDataStore();
// const museDataStore = useMuseDataStore();

// const bgmReferMusicStore = useBgmReferMusicStore();
// const bgmCreateEasyModeStore = useBgmCreateEasyModeStore();

const useDraftDataStore = defineStore({
  id: "draftDataStore",
  state: () => {
    return {
      draftData: {},
      feed_id: "",
      lastGenerateDraftData: null,
      createAdvancedModeAllData: {},
      bgmCreateAdvancedModeAllData: {},
      bgmCreateEasyModeAllData: {},
    };
  },
  actions: {
    tempCacheAdvanceData() {
      const lyricDataStore = useLyricDataStore();
      const museDataStore = useMuseDataStore();
      this.createAdvancedModeAllData = {
        lyricData: lyricDataStore.getLyricData(),
        museData: museDataStore.getMuseData(),
      }
    },

    tempBgmCacheAdvanceData() {
      const bgmReferMusicStore = useBgmReferMusicStore();
      this.bgmCreateAdvancedModeAllData = {
        refer: bgmReferMusicStore.getReferMusic(),
        songName: bgmReferMusicStore.songName,
      }
    },

    tempBgmCreateEasyModeData() {
      const bgmCreateEasyModeStore = useBgmCreateEasyModeStore();
      this.bgmCreateEasyModeAllData = {
        easyPromptData: bgmCreateEasyModeStore.getEasyPromptData(),
      }
    },

    restoreCacheAdvanceData() {
      const lyricDataStore = useLyricDataStore();
      const museDataStore = useMuseDataStore();
      lyricDataStore.setLyricData(this.createAdvancedModeAllData?.lyricData ?? {});
      museDataStore.setMuseData(this.createAdvancedModeAllData?.museData ?? {});
    },

    restoreBgmCacheAdvanceData() {
      const bgmReferMusicStore = useBgmReferMusicStore();
      bgmReferMusicStore.setReferMusic(this.bgmCreateAdvancedModeAllData?.refer ?? {});
      bgmReferMusicStore.setSongName(this.bgmCreateAdvancedModeAllData?.songName ?? "")
    },

    restoreBgmCreateEasyModeData() {
      const bgmCreateEasyModeStore = useBgmCreateEasyModeStore();
      bgmCreateEasyModeStore.setEasyPromptData(this.bgmCreateEasyModeAllData?.easyPromptData ?? {});
    },

    cacheSimilarAdvanceData(advanceModeSimilarDialogVisible) {
      const lyricDataStore = useLyricDataStore();
      const museDataStore = useMuseDataStore();
      const createAdvancedModeAllData = {
        advanceModeSimilarDialogVisible: advanceModeSimilarDialogVisible,
        lyricData: lyricDataStore.getLyricData(),
        museData: museDataStore.getMuseData(),
      }
      localStorage.setItem(`${globalConfig.appname}_draftData_similar_advance_data`, JSON.stringify(createAdvancedModeAllData));
      localStorage.setItem(`${globalConfig.appname}_draftData_advance_data`, JSON.stringify(this.createAdvancedModeAllData));
    },

    cacheBgmSimilarAdvanceData(advanceModeSimilarDialogVisible) {
      const bgmReferMusicStore = useBgmReferMusicStore();
      const bgmCreateAdvancedModeAllData = {
        refer: bgmReferMusicStore.getReferMusic(),
        songName: bgmReferMusicStore.songName,
        advanceModeSimilarDialogVisible: advanceModeSimilarDialogVisible,
      }
      localStorage.setItem(`${globalConfig.appname}_draftData_bgm_similar_advance_data`, JSON.stringify(bgmCreateAdvancedModeAllData));
      localStorage.setItem(`${globalConfig.appname}_draftData_bgm_advance_data`, JSON.stringify(this.bgmCreateAdvancedModeAllData));
    },

    cacheBgmSimilarEasyModeData() {
      const bgmCreateEasyModeStore = useBgmCreateEasyModeStore();
      const bgmCreateEasyModeAllData = {
        easyPromptData: bgmCreateEasyModeStore.getEasyPromptData(),
      }
      localStorage.setItem(`${globalConfig.appname}_draftData_bgm_similar_easy_data`, JSON.stringify(bgmCreateEasyModeAllData));
      localStorage.setItem(`${globalConfig.appname}_draftData_bgm_easy_data`, JSON.stringify(this.bgmCreateEasyModeAllData));
    },

    restoreSimilarCacheAdvanceData(isResetProRefer) {
      return new Promise((resolve, reject) => {
        const lyricDataStore = useLyricDataStore();
        const museDataStore = useMuseDataStore();
        const userDataStore = useUserDataStore();

        let createAdvancedModeSimilarAllData = null;
        let createAdvancedModeAllData = {};

        try {
          createAdvancedModeSimilarAllData = JSON.parse(localStorage.getItem(`${globalConfig.appname}_draftData_similar_advance_data`));
          createAdvancedModeAllData = JSON.parse(localStorage.getItem(`${globalConfig.appname}_draftData_advance_data`));
          if (isResetProRefer && userDataStore.userInfo?.user?.vip_level_name == 'pro') {
            Object.values(createAdvancedModeSimilarAllData.museData).forEach((item) => {
              if (item?.is_subscription_privilege) {
                item.is_subscription_privilege = false;
              }
            })
          }
        }
        catch (e) {
        }
        if (createAdvancedModeSimilarAllData) {
          lyricDataStore.setLyricData(createAdvancedModeSimilarAllData.lyricData);
          museDataStore.setMuseData(createAdvancedModeSimilarAllData.museData);
        }
        if (createAdvancedModeAllData) {
          this.createAdvancedModeAllData = createAdvancedModeAllData;
        }
        resolve(createAdvancedModeSimilarAllData.advanceModeSimilarDialogVisible)
      })
    },

    restoreBgmSimilarCacheAdvanceData() {
      return new Promise((resolve, reject) => {
        const bgmReferMusicStore = useBgmReferMusicStore();
        let createAdvancedModeAllData = null;
        let createAdvancedModeSimilarAllData = null;
        try {
          createAdvancedModeAllData = JSON.parse(localStorage.getItem(`${globalConfig.appname}_draftData_bgm_advance_data`));
          createAdvancedModeSimilarAllData = JSON.parse(localStorage.getItem(`${globalConfig.appname}_draftData_bgm_similar_advance_data`));
        }
        catch (e) {
        }
        if (createAdvancedModeAllData) {
          this.bgmCreateAdvancedModeAllData = createAdvancedModeAllData;
        }
        if (createAdvancedModeSimilarAllData) {
          bgmReferMusicStore.setrefer(createAdvancedModeSimilarAllData.refer);
          bgmReferMusicStore.setSongName(createAdvancedModeSimilarAllData.songName);
        }
        resolve(createAdvancedModeSimilarAllData.advanceModeSimilarDialogVisible)
      })
    },

    restoreBgmSimilarCacheEasyModeData() {
      return new Promise((resolve, reject) => {
        const bgmCreateEasyModeStore = useBgmCreateEasyModeStore();
        let createEasyModeSimilarAllData = null;
        let createEasyModeAllData = null;
        try {
          createEasyModeSimilarAllData = JSON.parse(localStorage.getItem(`${globalConfig.appname}_draftData_bgm_similar_easy_data`));
          createEasyModeAllData = JSON.parse(localStorage.getItem(`${globalConfig.appname}_draftData_bgm_easy_data`));
        } catch (e) {
        }
        if (createEasyModeSimilarAllData) {
          bgmCreateEasyModeStore.setEasyPromptData(createEasyModeSimilarAllData.easyPromptData);
        }
        if (createEasyModeAllData) {
          this.bgmCreateEasyModeAllData = createEasyModeAllData;
        }
        resolve(createEasyModeSimilarAllData.easyModeSimilarDialogVisible)
      })
    },


    upDateSimilarCacheAdvanceData() {
      const museDataStore = useMuseDataStore();
      const museData = museDataStore.getMuseData();
      if (userDataStore.userInfo?.user?.vip_level_name == 'pro') {
        Object.values(museData).forEach((item) => {
          if (item?.is_subscription_privilege) {
            item.is_subscription_privilege = false;
          }
        })
      }
      museDataStore.setMuseData(museData);
    },
    setAdvancedModeAllData(data) {
      const lyricDataStore = useLyricDataStore();
      const museDataStore = useMuseDataStore();
      lyricDataStore.initLyricData(data);
      museDataStore.initMuseData(data);
    },

    setBgmAdvancedModeAllData(data) {
      const bgmReferMusicStore = useBgmReferMusicStore();
      bgmReferMusicStore.setReferMusic(data.refer);
      bgmReferMusicStore.setSongName(data.title);
    },


    setDraftData(data, isReset = false) {
      // mode: 0-默认 1-ez 
      const lyricDataStore = useLyricDataStore();
      const museDataStore = useMuseDataStore();
      const bgmReferMusicStore = useBgmReferMusicStore();
      const bgmCreateEasyModeStore = useBgmCreateEasyModeStore();
      const createLayoutStore = useCreateLayoutStore();
      this.draftData = data ?? {};
      this.feed_id = data?.feed_id;
      const createEasyModeStore = useCreateEasyModeStore();
      if (data?.mode == 1) {
        createEasyModeStore.initEasyPromptData(data, isReset);
        createLayoutStore.setCreateEditMode("easy");
      } else if (!data?.mode) {
        lyricDataStore.initLyricData(data, isReset);
        museDataStore.initMuseData(data);
        createLayoutStore.setCreateEditMode("hard");
      } else if (data?.mode == 3) {
        bgmReferMusicStore.setReferMusic(data.refer, true);
        bgmReferMusicStore.setSongName(data.title);
        createLayoutStore.setBgmCreateEditMode("hard");
      } else if (data?.mode == 4) {
        bgmCreateEasyModeStore.initEasyPromptData(data);
        createLayoutStore.setBgmCreateEditMode("easy");
      } else {
        lyricDataStore.initLyricData(data, isReset);
        museDataStore.initMuseData(data);
        createLayoutStore.setCreateEditMode("hard");
      }
    },

    geLocalDraftData() {
      const lyricDataStore = useLyricDataStore();
      const museDataStore = useMuseDataStore();
      const createEasyModeStore = useCreateEasyModeStore();
      const bgmReferMusicStore = useBgmReferMusicStore();
      const bgmCreateEasyModeStore = useBgmCreateEasyModeStore();
      return {
        museData: museDataStore.getMuseData(),
        lyricData: lyricDataStore.getLyricData(),
        easyModeData: createEasyModeStore.getEasyModeData(),
        bgmReferData: {
          refer: bgmReferMusicStore.getReferMusic(),
          songName: bgmReferMusicStore.getSongName()
        },
        bgmEasyModeData: bgmCreateEasyModeStore.getEasyModeData(),
      }
    },
    setLocalDraftData(data) {
      const createEasyModeStore = useCreateEasyModeStore();
      const lyricDataStore = useLyricDataStore();
      const museDataStore = useMuseDataStore();
      const bgmReferMusicStore = useBgmReferMusicStore();
      const bgmCreateEasyModeStore = useBgmCreateEasyModeStore();
      lyricDataStore.setLyricData(data.lyricData);
      museDataStore.setMuseData(data.museData);
      createEasyModeStore.setEasyModeData(data.easyModeData);
      bgmReferMusicStore.setReferMusic(data.bgmReferData?.refer);
      bgmReferMusicStore.setSongName(data.bgmReferData?.songName);
      bgmCreateEasyModeStore.setEasyModeData(data.bgmEasyModeData);
    },

    cacheLocalDraftData() {
      // localStorage.setItem(
      //   `${globalConfig.appname}_draftData`,
      //   JSON.stringify(this.geLocalDraftData())
      // )
    },
    getLocalCacheDraftData() {
      try {
        return JSON.parse(localStorage.getItem(`${globalConfig.appname}_draftData`));
      } catch (e) {
        return null;
      }
    },
    getDraftData(params = {}) {
      const lyricDataStore = useLyricDataStore();
      const museDataStore = useMuseDataStore();
      this.closeActionDialogCallback && this.closeActionDialogCallback();
      this.closeActionDialogCallback = null;
      return new Promise((resolve, reject) => {
        feedListApi
          .getDraftData(params)
          .then((res) => {
            const { code, data, msg } = res;
            if (code == 200) {
              this.draftData = data ?? {};
              this.feed_id = data?.feed_id;
              lyricDataStore.initLyricData(data);
              museDataStore.initMuseData(data);
              useFeedListStore().init({});
              resolve();
            } else {
              if (code == 401) {
                reject("Authentication failed");
                return;
              }

              this.closeActionDialogCallback = ShowActionDialog({
                content: $$t("create.draft_failed"),
                width: "500px",
                confirmLabel: $$t("create.retry"),
                hideCancel: true,
                showCloseBtn: true,
                close: (closeAction) => {
                  this.closeActionDialogCallback();
                  this.closeActionDialogCallback = null;
                },
                confirm: async (closeAction) => {
                  this.closeActionDialogCallback();
                  this.closeActionDialogCallback = null;
                  try {
                    await this.getDraftData(params);
                    resolve();
                  } catch (e) {
                    reject('get draft data failed');
                  }
                },
              });
            }
          })
          .catch((e) => {
            this.closeActionDialogCallback = ShowActionDialog({
              content: $$t("create.draft_failed"),
              width: "500px",
              confirmLabel: $$t("create.retry"),
              hideCancel: true,
              showCloseBtn: true,
              close: (closeAction) => {
                this.closeActionDialogCallback();
                this.closeActionDialogCallback = null;
              },
              confirm: async (closeAction) => {
                this.closeActionDialogCallback();
                this.closeActionDialogCallback = null;
                this.getDraftData(params);
              },
            });
            reject('get draft data failed');
          });
      });
    },
    resetDraftData(isSwitchLanguage) {
      const lyricDataStore = useLyricDataStore();
      const museDataStore = useMuseDataStore();
      const createEasyModeStore = useCreateEasyModeStore();
      const bgmReferMusicStore = useBgmReferMusicStore();
      const bgmCreateEasyModeStore = useBgmCreateEasyModeStore();
      lyricDataStore.initLyricData({});
      museDataStore.initMuseData({});
      createEasyModeStore.initEasyPromptData({});
      bgmReferMusicStore.setReferMusic(null);
      bgmReferMusicStore.setSongName("");
      bgmReferMusicStore.clearAllReferSelectData(isSwitchLanguage);
      bgmCreateEasyModeStore.initEasyPromptData();
    },
    clearAll(isSwitchLanguage) {
      const creatVideoEditDataStore = useCreatVideoEditDataStore();
      const createLayoutStore = useCreateLayoutStore();
      const museDataStore = useMuseDataStore();
      this.resetDraftData(isSwitchLanguage);
      creatVideoEditDataStore.clearAllData();
      createLayoutStore.setActivityConfigTab('create')
      museDataStore.clearAllReferSelectData();
    },
    updateFeedId(newDraftId) {
      this.feed_id = newDraftId;
    },
    setLastGenerateDraftData(feedData) {
      this.lastGenerateDraftData = feedData;
    },
    getLocalDraftData() { },
    clearData() {
      useFeedListStore().clearData();
      useCreatVideoResultDataStore().clearData();
      this.clearAll();
    },
    getGenerateDraftData({ similarData } = {}) {
      const lyricDataStore = useLyricDataStore();
      const museDataStore = useMuseDataStore();
      let museData = museDataStore.getMuseGenerateData();
      const modelDataStore = useModelDataStore();

      if (modelDataStore.current_model?.supported_control_types && !similarData) {
        museData = modelDataStore.current_model?.supported_control_types.reduce((res, cur) => {
          if (museData[cur]) {
            res[cur] = museData[cur];
          }
          return res;
        }, {});
      }
      return {
        // feed_id: this.feed_id,
        mode: 0,
        ...museData,
        ...lyricDataStore.getLyricData(),
      };
    },

    getBgmGenerateAdvanceDraftData() {
      const bgmReferMusicStore = useBgmReferMusicStore();
      return {
        mode: 3,
        refer: bgmReferMusicStore.getReferMusic(),
        title: bgmReferMusicStore.songName || 'Untitled',
      }
    },

    async getGenerateDraftDataEasyMode() {
      const createEasyModeStore = useCreateEasyModeStore();
      return {
        mode: 1,
        prompt: createEasyModeStore.getEasyPromptData(),
      };
    },

    async getBgmGenerateDraftDataEasyMode() {
      const bgmCreateEasyModeStore = useBgmCreateEasyModeStore();
      return {
        mode: 4,
        prompt: bgmCreateEasyModeStore.getEasyPromptData(),
      };
    },

    initPresetData() {
      const createEasyModeStore = useCreateEasyModeStore();
      const referMusicStore = userReferMusicStore();
      const vocalMusicStore = userVocalMusicStore();
      const motifDataStore = userMotifDataStore();
      const promptDataStore = usePromptDataStore();

      const bgmCreateEasyModeStore = useBgmCreateEasyModeStore();
      const bgmReferMusicStore = useBgmReferMusicStore();

      referMusicStore.initLibraryData();
      vocalMusicStore.initLibraryData();
      createEasyModeStore.initLibraryData();
      promptDataStore.initLibraryData();
      motifDataStore.initLibraryData();

      bgmCreateEasyModeStore.initLibraryData();
      bgmReferMusicStore.initLibraryData();
    }
  },
});
export default useDraftDataStore;
// export const draftDataStore = useDraftDataStore()
