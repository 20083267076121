<template>
  <div
    class="height-all custom-empty flex-center"
    :class="`render--${props.type} custom-empty--${createRenderType}`"
  >
    <div class="custom-empty-main" v-if="!props.loading">
      <div class="custom-empty-main-ico"></div>
      <div class="custom-empty-main-info">
        {{ renderData.emptyInfo[props.type] }}
      </div>
      <div
        class="custom-empty-main-retry"
        v-if="!props.hideRetry && renderData.retryBtnText"
      >
        <Btn
          :renderStyle="{
            '--padding': '0 20px',
            '--width': 'max-content',
            '--height': '30px',
            '--borderRadius': '28px',
            '--backgroundHover': 'rgba(255, 255, 255, 0.2)',
            '--background': 'rgba(255, 255, 255, 0.1)',
            '--fontColor': '#fff',
            '--fontSize': '14px',
            '--fontWeight': '400',
            '--border': 'none',
          }"
          @click.close="handleRetry"
          >{{ renderData.retryBtnText ?? $$t("common.reload") }}
        </Btn>
      </div>
    </div>
    <div class="custom-empty-loading" v-if="props.loading">
      <div class="custom-empty-loading-ico"></div>
      <div class="custom-empty-loading-text">{{ $$t("common.loading") }}</div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  computed,
  readonly,
  reactive,
  watch,
  defineExpose,
  onMounted,
} from "vue";
import Btn from "@/components/basic/button/Button.vue";
import { $$language, $$t } from "@/i18n/i18n.js";
import { useRouter, useRoute } from "vue-router";

import useUserDataStore from "@/store/userDataStore";
import useCreateLayoutStore from "@/store/create/createLayoutStore.js";
import { storeToRefs } from "pinia";

const userDataStore = useUserDataStore();
const createLayoutStore = useCreateLayoutStore();
const { createRenderType } = storeToRefs(createLayoutStore);
const Router = useRouter();
const route = useRoute();

const getEmptyInfo = () => {
  return {
    networkerror: $$t("common.network_error"),
    loadfailure: $$t("common.load_failure"),
    contentviolation: $$t("common.content_violation"),
    emptydata: $$t("common.empty_data"),
  };
};

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  hideRetry: {
    type: Boolean,
    default: false,
  },
  info: {
    type: Object,
    default: () => {
      return null;
    },
  },
  type: {
    type: String,
    default: "loadfailure",
    // networkerror 网络错误
    // loadfailure 加载失败
    // contentviolation 内容违规
    // emptydata 无数据
  },
  btnText: {
    type: Object,
    default: null,
  },
});
const defaultBtnText = {
  networkerror: $$t("common.reload"),
  loadfailure: $$t("common.reload"),
};
const emits = defineEmits(["retry"]);
const renderData = reactive({
  emptyInfo: getEmptyInfo(),
  retryBtnText: (props.btnText ?? defaultBtnText)[props.type],
});

const handleRetry = () => {
  emits("retry");
};

watch(
  () => userDataStore.appLanguage,
  (newVal) => {
    nextTick().then(() => {
      Object.assign(defaultBtnText, {
        networkerror: $$t("common.reload"),
        loadfailure: $$t("common.reload"),
      });
      renderData.emptyInfo = props.info ?? getEmptyInfo();
      renderData.retryBtnText = (props.btnText ?? defaultBtnText)[props.type];
    });
  }
);

watch(
  () => {
    return props.info;
  },
  () => {
    renderData.emptyInfo = props.info ?? getEmptyInfo();
  }
);

onMounted(() => {});
</script>
<style lang="scss">
.custom-empty {
  .custom-empty-main {
    padding: 0 54px 15px 54px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .custom-empty-main-ico {
      height: 150px;
      width: 150px;
      background: url("@/assets/img/empty-ico.png");
      background-size: 100% 100%;
    }
    .custom-empty-main-info {
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.5);
      line-height: 28px;
      margin-top: -6px;
    }
    .custom-empty-main-retry {
      margin-top: 16px;
    }
  }
  &.networkerror {
    .custom-empty-main {
      .custom-empty-main-ico {
        background: url("@/assets/img/empty-ico.png");
        background-size: 100% 100%;
      }
    }
  }
  &.loadfailure {
    .custom-empty-main {
      .custom-empty-main-ico {
        background: url("@/assets/img/empty-ico.png");
        background-size: 100% 100%;
      }
    }
  }
  &.contentviolation {
    .custom-empty-main {
      .custom-empty-main-ico {
        background: url("@/assets/img/empty-ico.png");
        background-size: 100% 100%;
      }
    }
  }
  &.emptydata {
    .custom-empty-main {
      .custom-empty-main-ico {
        background: url("@/assets/img/empty-ico.png");
        background-size: 100% 100%;
      }
    }
  }
  .custom-empty-loading {
    padding: 0 54px 15px 54px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: content;
    .custom-empty-loading-ico {
      height: 32px;
      width: 32px;
      background: url("@/assets/img/loading-mini-3x.png");
      background-size: 100% 100%;
      animation: animation-rotate-infinite 0.75s infinite normal linear;
    }
    .custom-empty-loading-text {
      font-family: HarmonyOS Sans SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.5);
      margin-top: 20px;
    }
  }
  &.custom-empty--mobile {
    .custom-empty-main {
      padding: 0 54px 15px 54px;

      .custom-empty-main-ico {
        height: 120px;
        width: 120px;
      }
      .custom-empty-main-info {
        font-size: 12px;
        line-height: 16px;
        margin-top: -6px;
      }
      .custom-empty-main-retry {
        margin-top: 16px;
      }
    }
  }
}
</style>
