<template>
  <div
    class="clipping-select-duration-edit"
    :class="{
      [`clipping-select-duration-edit--${createRenderType}`]: true,
    }"
  >
    <div class="clipping-select-duration-edit-main">
      <div class="clipping-select-duration-edit-op fn-inline">
        <div class="flex-center height-all">
          <LoaderProgressCircle
            :size="createRenderType == 'mobile' ? 40 : 48"
            default-color="rgba(255,255,255,1)"
            progress-color="#94ADFF"
            :progress="0"
          >
            <div
              class="audio-item-op pointer flex-center"
              @click.stop="doTogglePlay"
            >
              <i
                class="block"
                :class="{
                  'isplay-true': !renderData.isPlaying,
                  'isplay-false': renderData.isPlaying,
                }"
              ></i>
            </div>
          </LoaderProgressCircle>
        </div>
      </div>
      <div
        class="clipping-select-duration-edit-margin left-margin fn-inline"
      ></div>
      <div class="clipping-select-duration-edit-main-box fn-inline">
        <div class="clipping-select-duration-edit-main">
          <ClippingSelectDurationOparation
            :config="props.config"
            :url="renderData.url"
            :duration="renderData.duration"
            :waveformData="renderData.waveformData"
            ref="refDragSelect"
            @syncPlayState="doSyncPlayState"
            @syncProgress="doSyncProgress"
            @syncDuration="doSyncDuration"
            @syncWaveLoadingState="doSyncWaveLoadingState"
            @syncSelectData="doSyncSelectData"
            @selectDataChange="doSelectDataChange"
            @selectChangeLimit="doSelectChangeLimit"
          ></ClippingSelectDurationOparation>
        </div>
      </div>
      <div
        class="clipping-select-duration-edit-margin right-margin fn-inline"
      ></div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  computed,
  readonly,
  reactive,
  watch,
  defineExpose,
  onMounted,
  onUpdated,
  onUnmounted,
} from "vue";
import LoaderProgressCircle from "@/components/animation/LoaderProgressCircle.vue";
import EditSelectArea from "./ClippingSelectDurationOparation.vue";
import mediaUtil from "@/components/media/utils/utils.js";

import { storeToRefs } from "pinia";
import useCreateLayoutStore from "@/store/create/createLayoutStore.js";
const createLayoutStore = useCreateLayoutStore();
const { createRenderType } = storeToRefs(createLayoutStore);

// const audioUrl = "https://test.mureka.ai/cos-test/song/audio/20250210/music_55531918065665_4GRm6XVST3V2Y2FJvovoum_mxchmh.mp3";

const props = defineProps({
  config: {
    type: Object,
    default: () => {
      return {};
    },
  },
  duration: {
    type: Number,
    default: 0,
  },
  url: {
    type: String,
    default: null,
  },
  waveformData: {
    type: Array,
    default: () => {
      return [];
    },
  },
});

const emits = defineEmits([
  "syncWaveLoadingState",
  "syncSelectData",
  "selectDataChange",
  "syncPlayState",
  "syncProgress",
  "syncDuration",
  "selectChangeLimit",
]);
const refDragSelect = ref(null);
const stateData = {};
const renderData = reactive({
  url: props.url,
  waveformData: props.waveformData,
  duration: props.duration,
  isPlaying: false,
  progress: 0,
  startTime: "00:00",
  duration: 0,
  sourceData: null,
});
const doTogglePlay = () => {
  refDragSelect.value.togglePlay();
};

const doSyncPlayState = (isPlaying) => {
  renderData.isPlaying = isPlaying;
};

const doSyncProgress = (progress, startTime) => {
  renderData.progress = progress;
  renderData.startTime = startTime;
};

const doSyncDuration = (duration) => {
  renderData.duration = duration;
};
const doSyncWaveLoadingState = (loading) => {
  emits("syncWaveLoadingState", loading);
};

const doSyncSelectData = (data) => {
  emits("syncSelectData", data);
};

const doSelectDataChange = (data) => {
  emits("selectDataChange", data);
};

const doSelectChangeLimit = (data) => {
  emits("selectChangeLimit", data);
};

const init = () => {
  // 初始化操作
  renderData.url = props.url;
  renderData.waveformData = props.waveformData;
  renderData.duration = props.duration;
};

watch(
  () => renderData.isPlaying,
  (newValue) => {
    emits("syncPlayState", renderData.isPlaying);
  }
);
watch(
  () => renderData.progress,
  (newValue) => {
    emits("syncProgress", renderData.progress, renderData.startTime);
  }
);
watch(
  () => renderData.duration,
  (newValue) => {
    emits("syncDuration", renderData.duration);
  }
);

watch(
  () => {
    return props.duration;
  },
  () => {
    renderData.duration = props.duration;
  }
);
watch(
  () => {
    return props.url;
  },
  () => {
    renderData.url = props.url;
  }
);

onMounted(() => {
  init();
});

defineExpose({
  getRenerateSelectTime() {
    return refDragSelect.value?.getRenerateSelectTime();
  },
  setRenerateSelectTime(data) {
    refDragSelect.value?.setRenerateSelectTime(data);
  },
});
</script>
<style lang="scss">
.clipping-select-duration-edit {
  --opHeight: 88px;
  .clipping-select-duration-edit-time-box {
    display: flex;
    padding-bottom: 8px;
    font-family: HarmonyOS Sans SC;
    font-size: 12px;
    font-weight: normal;
    line-height: 20px;
    color: #ffffff;
    .clipping-select-duration-edit-time {
    }
  }
  .clipping-select-duration-edit-main {
    .clipping-select-duration-edit-op {
      height: var(--opHeight);
      width: 88px;
      background: rgba(148, 173, 255, 0.3);
      // border-right: 2px solid rgba(148, 173, 255, 0.2);
      .loader-progress:hover {
        opacity: 0.75;
      }
      .audio-item-op {
        height: 100%;
        width: 100%;
      }
      i {
        height: 24px;
        width: 24px;
      }
      .isplay-true {
        background: url("@/assets/img/play-light-ico.png");
        background-size: 100% 100%;
      }
      .isplay-false {
        background: url("@/assets/img/pause-light-ico.png");
        background-size: 100% 100%;
      }
    }
  }
  .clipping-select-duration-edit-margin {
    width: 0;
    background: rgba(148, 173, 255, 0.3);
    height: var(--opHeight);
  }
  .clipping-select-duration-edit-main-box {
    width: calc(100% - 48px - 20px * 2);
  }
  &.clipping-select-duration-edit--mobile {
    .clipping-select-duration-edit-main {
      .clipping-select-duration-edit-op {
        height: 88px;
        width: 64px;
        background: rgba(148, 173, 255, 0.3);
        // border-right: 2px solid rgba(148, 173, 255, 0.2);
        i {
          height: 16px;
          width: 16px;
        }
      }
    }
    .clipping-select-duration-edit-margin {
      height: 88px;
      background: rgba(148, 173, 255, 0.3);
    }
    .clipping-select-duration-edit-main-box {
      width: calc(100% - 40px - 12px * 2);
    }
  }
}
</style>
