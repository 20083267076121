import { isDev, isProd, urlIncludeDomain } from "@/utils/tools.js";
import GlobalAudioPlayer from "@/components/media/audioPlayer/GlobalAudioPlayer.js";
import { baseUrl } from '@/config/apiConfig.js';

import {
  $$language,
  $$t,
  $$registLanguageChangeListener,
  $$removeLanguageChangeListener,
} from "@/i18n/i18n.js";
import { isMobileDevice, murekaAppPlantfrom } from "@/utils/tools.js"

const globleConfig = {
  appname: import.meta.env.VITE_APPNAME, // "Mureka", YinFeng
  platform: isMobileDevice() ? 'h5' : 'web',
  MAX_LYRIC_COUNT: 2000,
  curObjectStorageType: "OSS", // OSS || COS
  version: "1.0.1",
  localStorageGlobalKey: "skywork_music_ai",
  curObjectStorageExpirTimeForwardTo: 30 * 1000, //mm
  baseObjectStorageUrl: import.meta.env.VITE_COS_BASE_URL,
  baseAssetsUrl:
    import.meta.env.VITE_PUBLIC_PATH == "" || import.meta.env.VITE_PUBLIC_PATH == "/"
      ? window.location.origin
      : import.meta.env.VITE_PUBLIC_PATH,
  baseOrigin: window.location.origin, // import.meta.env.VITE_APP_BASE_ORIGIN,

  switchLanguage: import.meta.env.VITE_SWITCH_LANGUAGE === "true",
  murekaAppPlantfrom : murekaAppPlantfrom(),
  stripeKey: import.meta.env.VITE_STRIPE_KEY ?? '',
  abtestUrl: import.meta.env.VITE_ABTEST_URL ?? '',
  paypalConsoleUrl: import.meta.env.VITE_PAYPAL_CONSOLE_URL ?? '',
  paypalKey: import.meta.env.VITE_PAYPAL_KEY ?? ''
};
export const getGuidanceJsonDataUrl = () => {
  return $$language() == "cn"
    ? isProd()
      ? "cos-prod/res/default/yinfeng.json"
      : "cos-test/res/default/yinfeng.json"
    : isProd()
      ? "cos-prod/res/default/mureka.json"
      : "cos-test/res/default/mureka.json";
};

// setTimeout(() => {
//   $$registLanguageChangeListener(() => {
//     globleConfig.MAX_LYRIC_COUNT = $$language() == "en" ? 1500 : 600;
//   });
// })
export default globleConfig;

export const chinaAppName = "YinFeng";
export const overseasAppName = "Mureka";

export const linkMap = {
  waitingList: "www.baidu.com",
  youtube: "https://www.youtube.com/@Melodioai",
  twitter: "https://x.com/Melodioai",
  Discord: "https://discord.gg/aSGcjSYB8A",
  termsofservice:
    "https://plausible-nape-c6d.notion.site/Terms-of-Service-794a545bc9f24996a90bca2801aab8df?pvs=4",
  privacypolicy:
    "https://plausible-nape-c6d.notion.site/Privacy-Policy-eb0e6079c3c34b0c9e5b3eaceb4ae8d8?pvs=4",
};

export const openLink = (link, _isBlank) => {
  window.open(link, _isBlank);
};



export const getStaticFullUrl = (url) => {
  if (urlIncludeDomain(url)) {
    return url;
  }
  return window.location.origin + url;
}


// 功能模块开关， 多语言开关在环境变量里面
export const ModuleOpenStateControl = {
  create: {
    release: false, //  是否开启发布
    generatingStream: GlobalAudioPlayer.isAudioReadableStreamSupported(), // 是否支持流式生成和播放
    syncPlayWhenGenerated: true, // 是否在生成时同步播放替换伪流式播放为mp3并同步播放路径
    vocalCustom: true, // 是否开启人声定制
    motifReferCustom: isDev(), // 是否开启motif（melody ideas）定制
    motifReferCustomMidi: true, // 是否开启motif（melody ideas）midi选项
    vacalCustomNewCornerMarkEndTime: "2025-01-15 23:59:59",
  },
  home:{
    similar: true // 是否开启相似歌曲推荐
  },
  createVideo: {
    opened: true, // 是否开启视频生成
    endTime: "2025-12-31 23:59:59", // 视频生成结束时间
  },
  editor: {
    opened: isDev(), // 是否开启编辑器
    regionEditing: true, // 是否开启区域编辑
    extendEditing: true, // 是否开启扩展编辑
    trackStem: isDev(), // 是否开启轨道编辑
    coverVoice: false, // 是否开启人声遮罩
    lyricsTranslate: isDev(), // 是否开启歌词翻译
    regionEditingBeta: true, // 是否开启区域编辑beta
    extendEditingBeta: true, // 是否开启扩展编辑beta
    trackStemBeta: true, // 是否开启轨道编辑beta
    coverVoiceBeta: true, // 是否开启人声遮罩beta
    lyricsTranslateBeta: true, // 是否开启歌词翻译beta
  },
  model:{
    opened: true, // 是否开启模型选择
    custom: true// 是否开启模型定制,
  }
}

export const getPipeReadableFlowUrl = (stremKey) => {
  return `${baseUrl}/api/live/pipe/audio?stream_key=${stremKey}`;
}