<template>
  <div>
    <Dialog
      width="904px"
      :height="createRenderType == 'pc' ? '453px' : ''"
      :mobileRenderType="createRenderType == 'pc' ? '' : 'drawerDialog'"
      :customClass="`${createRenderType}-credit-dialog`"
      titleAlign="left"
      :visible="dialogVisible"
      @close="onClose"
    >
      <div>
        <div class="title">{{ $$t("credit.addCredits") }}</div>
        <div class="select">
          <div
            class="select-item"
            @click="changeIndex(num)"
            :class="{ light: num == currentIndex }"
            v-for="(info, num) in channel"
            :key="num"
          >
            <i></i>{{ info.name }}
          </div>
        </div>
        <div class="flex list">
          <template v-if="creditList">
            <div
              class="item"
              v-for="(item, index) in creditList"
              :key="item.product_id"
            >
              <div class="name">
                {{ item.base_credit }} {{ $$t("credit.credits") }}
              </div>
              <div class="unit">{{ item.unit_price }}</div>
              <div class="price-btn">
                <span class="flag">$</span>
                <span class="num">{{ item.price }}</span>
              </div>

              <div class="btn-box">
                <div class="btn pointer" @click="handleCheckout(item, index)">
                  {{ $$t("store.Checkout") }}
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  onMounted,
  defineEmits,
} from "vue";
import { storeToRefs } from "pinia";
import { getNewProducts, checkoutCredit } from "@/api/api/apiCredit.js";
import useCreateLayoutStore from "@/store/create/createLayoutStore.js";
import { $$t } from "@/i18n/i18n.js";
import message from "@/components/functionCallComponent/message/message.js";

const createLayoutStore = useCreateLayoutStore();
const { createRenderType } = storeToRefs(createLayoutStore);
const emits = defineEmits(["update:visible", "handleCreditCheckout"]);

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
});

const dialogVisible = ref(props.visible);
const creditList = ref([]);

const onClose = () => {
  dialogVisible.value = false;
  emits("update:visible", false);
};

const currentIndex = ref(0);

const channel = ref([
  {
    name: "Stripe",
  },
  {
    name: "Paypal",
  },
]);

const changeIndex = (index) => {
  currentIndex.value = index;
};

const getList = () => {
  getNewProducts({
    category: "normal",
    platform: "html",
  }).then((res) => {
    if (res.code == 200) {
      creditList.value = res?.data?.list || [];
    }
  });
};

const handleCheckout = (item) => {
  onClose();
  if (currentIndex.value == 0) {
    handleCreditCheckout(item, "stripe", "credit");
  } else {
    handleCreditCheckout(item, "paypal", "credit");
  }
};

const handleCreditCheckout = async (item, channel) => {
  const { price } = item;
  let product_id =
    channel == "stripe"
      ? item?.stripe_info?.product_id
      : item?.paypal_info?.product_id;
  checkoutCredit({
    product_id,
    channel,
    return_url: '',
  }).then((res) => {
    if (res.code == 200) {
      const { jump_url } = res.data;
      if (jump_url) {
        window.location.href = jump_url;
      }
    } else {
      codeDel(res.code, res.msg);
    }
  });
};

const codeDel = (code, msg) => {
  const errorCodes = [4, 8003, 8004, 8005, 8006, 8007, 8009, 8010, 8011, 8012];

  if (errorCodes.includes(code)) {
    message.error({
      content: msg,
    });
  }
};

onMounted(() => {
  getList();
});
</script>

<style lang="scss" scoped>
.pc-credit-dialog {
  .title {
    padding: 0 60px;
    font-size: 22px;
    font-weight: 500;
    line-height: 22px;
    color: #fff;
    margin-bottom: 24px;
  }
  .select {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding-left: 60px;
    font-size: 16px;
    font-weight: normal;
    line-height: 22px;
    gap: 20px;
    .select-item {
      display: flex;
      align-items: center;
      justify-content: left;
      gap: 8px;
      color: #fff;
      i {
        display: inline-block;
        width: 16px;
        height: 16px;
        background-image: url("@/assets/img/credit/ic-select.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
      }
      &.light {
        // color: #fff;
        i {
          background-image: url("@/assets/img/credit/ic-selected.png");
        }
      }
    }
  }
  .list {
    margin: 24px 60px 0;
    .item {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      padding: 24px;
      width: 248px;
      height: 295px;
      background: rgba(0, 0, 0, 0.2);
      border: 2px solid transparent;
      border-radius: 16px;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        border: 2px solid #94adff;
        background: rgba(0, 0, 0, 0.4);
      }
      .name {
        font-size: 24px;
        font-weight: bold;
        color: #fff;
      }
      .unit {
        margin-top: 12px;
        font-size: 14px;
        color: #fff;
      }
      .price-btn {
        margin-top: 48px;
        font-weight: bold;
        .flag {
          font-size: 40px;
          line-height: 58px;
        }
        .num {
          margin-left: 8px;
          font-size: 50px;
          vertical-align: bottom;
        }
        span {
          background: linear-gradient(118deg, #4fdeff 9%, #e675ff 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
      }
      .btn-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .btn {
          border-radius: 62px;
          background: linear-gradient(103deg, #4fdeff 0%, #e675ff 100%);
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          line-height: 48px;
          color: #000;
        }
      }
    }
  }
}

.mobile-credit-dialog {
  .title {
    padding: 0 16px;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    color: #fff;
  }
  .select {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding-left: 16px;
    font-size: 16px;
    font-weight: normal;
    line-height: 22px;
    gap: 20px;
    margin-top: 16px;
    .select-item {
      display: flex;
      align-items: center;
      justify-content: left;
      gap: 8px;
      color: #fff;
      i {
        display: inline-block;
        width: 16px;
        height: 16px;
        background-image: url("@/assets/img/credit/ic-select.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
      }
      &.light {
        // color: #fff;
        i {
          background-image: url("@/assets/img/credit/ic-selected.png");
        }
      }
    }
  }
  .list {
    margin: 22px 16px 0;
    flex-direction: column;
    .item {
      position: relative;
      padding: 24px;
      height: 156px;
      background: rgba(255, 255, 255, 0.1);
      border: 2px solid transparent;
      border-radius: 16px;
      margin-bottom: 12px;
      .name {
        font-size: 20px;
        font-weight: bold;
        color: #fff;
      }
      .unit {
        margin-top: 6px;
        font-size: 12px;
        color: #fff;
      }
      .price-btn {
        margin-top: 20px;
        font-weight: bold;
        .flag {
          font-size: 32px;
          line-height: 46px;
        }
        .num {
          font-size: 38px;
          vertical-align: bottom;
          margin-left: 2px;
        }
        span {
          background: linear-gradient(118deg, #4fdeff 9%, #e675ff 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
      }
      .btn {
        position: absolute;
        right: 32px;
        top: 50%;
        transform: translateY(-50%);
        padding: 8px 19px;
        border-radius: 62px;
        background: linear-gradient(103deg, #4fdeff 0%, #e675ff 100%);
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        color: #000;
      }
    }
  }
}
</style>
