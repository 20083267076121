<template>
  <div
    class="loader-progress"
    :class="{
      'loading-active': props.loading,
    }"
    :style="{
      '--loaderSize': `${props.size}px`,
      '--loaderColor': props.progressColor,
      '--loaderStrokeWidth': `${props.strokeWidth}px`,
      '--loaderProgress': props.progress,
      '--border': `solid ${props.strokeWidth}px ${props.defaultColor}`,
      '--loading-boder': `solid ${props.strokeWidth}px transparent`,
    }"
  >
    <div class="loader-progress-main-content height-all flex-center">
      <slot></slot>
    </div>
    <div
      class="loader-progress-main height-all flex-center"
      v-if="props.progress > 0"
    >
      <div class="loader-progress-circle">
        <svg :viewBox="`0 0 ${props.size} ${props.size}`">
          <circle
            :cx="props.size / 2"
            :cy="props.size / 2"
            :r="props.size / 2 - props.strokeWidth / 2"
            fill="none"
            :stroke-dasharray="circumference"
            :stroke-dashoffset="strokeDashoffset"
          ></circle>
        </svg>
      </div>
    </div>
    <div class="loader-progress-loading rotate-loading">
      <svg :viewBox="`0 0 ${props.size} ${props.size}`">
        <circle
          :cx="props.size / 2"
          :cy="props.size / 2"
          :r="props.size / 2 - props.strokeWidth / 2"
          fill="none"
          :stroke-dasharray="circumference"
          :stroke-dashoffset="circumference * 0.5"
        ></circle>
      </svg>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, computed, reactive, watch, onMounted } from "vue";

const props = defineProps({
  size: {
    type: Number,
    default: 50,
  },
  progress: {
    type: Number,
    default: 0,
  },
  progressColor: {
    type: String,
    default: "#72FFEC",
  },
  defaultColor: {
    type: String,
    default: "rgba(255, 255, 255, 0.5)",
  },
  marginSize: {
    type: Number,
    default: 0,
  },
  strokeWidth: {
    type: Number,
    default: 2,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const circumference = computed(
  () => 2 * Math.PI * (props.size / 2 - props.strokeWidth / 2)
);
const strokeDashoffset = computed(
  () => circumference.value * (1 - props.progress)
);

const renderData = reactive({
  loading: props.loading ?? false,
});

watch(
  () => props.loading,
  (newVal) => {
    renderData.loading = newVal;
  }
);

onMounted(() => {
  renderData.loading = props.loading ?? false;
});
</script>

<style lang="scss">
.loader-progress {
  position: relative;
  & {
    height: var(--loaderSize);
    width: var(--loaderSize);
  }
  .loader-progress-main-content {
    height: var(--loaderSize);
    width: var(--loaderSize);
    border-radius: 50%;
    border: var(--border);
  }
  .loader-progress-main {
    height: var(--loaderSize);
    width: var(--loaderSize);
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
  }
  .loader-progress-circle {
    width: var(--loaderSize);
    height: var(--loaderSize);
    border-radius: 50%;
    color: var(--loaderColor);
    transform-origin: center center;
    transform: rotate(-90deg);
  }
  circle {
    transform-origin: center center;
    stroke: currentColor;
    stroke-width: var(--loaderStrokeWidth);
    stroke-linecap: round;
    border-radius: 50%;
  }
  .loader-progress-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: var(--loaderSize);
    height: var(--loaderSize);
    color: #fff;
    animation-play-state: paused;
    // background: url("@/assets/img/loading-mini-white-3x.png");
    // background-size: 100%;
    visibility: hidden;
    pointer-events: none;
  }
  &.loading-active {
    .loader-progress-main-content {
      border: var(--loading-boder);
    }
    .loader-progress-main {
      display: none;
    }
    .loader-progress-loading {
      visibility: visible;
      animation-play-state: running;
    }
  }
}
</style>
