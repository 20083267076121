<template>
  <div
    class="image-upload-clip-mini"
    :style="renderData.renderStyle"
    :class="renderData.renderClass"
  >
      <file-pond
        ref="refUpload"
        v-if="renderData.ifRenderFilePond"
        v-bind:allow-multiple="false"
        @handleAfterRead="handleAfterRead"
        @addfile="handleAddFile"
        :disabled="renderData.imgBlobUrl"
        v-bind:files="renderData.files"
        v-bind:disabled="renderData.disabled"
      >
      </file-pond>

    <div class="iuc-content flex" ref="refUploadContent">
      <div
        class="iuc-img-clip-box"
        ref="refImgClipBox"
        @click="
          () => {
            if (!renderData.imgBlobUrl) {
              handleSelectFile();
            }
          }
        "
      >
        <div class="iuc-img-clip-bg">
          <div
            class="iuc-img-render"
            :style="{
              transform: `translateX(${renderData.translateX}px) translateY(${renderData.translateY}px`,
              width: `${
                renderData.imgBoxWidth *
                renderData.imgScaleX *
                (1 + renderData.scaleRatio)
              }px`,
              height: `${
                renderData.imgBoxHeight *
                renderData.imgScaleY *
                (1 + renderData.scaleRatio)
              }px`,
              backgroundImage: `url(${renderData.imgBlobUrl})`,
              backgroundSize: '100% 100%',
            }"
          ></div>
        </div>
        <div class="iuc-img-clip-main">
          <div
            class="iuc-img-clip-select-area"
            ref="refImgClipSelectArea"
            :style="
              renderData.imgBlobUrl
                ? {}
                : {
                    background: `url(${ImgEmpty})`,
                    backgroundSize: 'cover',
                  }
            "
          >
            <div
              class="iuc-img-render"
              :style="{
                transform: `translateX(${renderData.translateX}px) translateY(${renderData.translateY}px`,
                width: `${
                  renderData.imgBoxWidth *
                  renderData.imgScaleX *
                  (1 + renderData.scaleRatio)
                }px`,
                height: `${
                  renderData.imgBoxHeight *
                  renderData.imgScaleY *
                  (1 + renderData.scaleRatio)
                }px`,
                backgroundImage: `url(${renderData.imgBlobUrl})`,
                backgroundSize: '100% 100%',
              }"
            ></div>
          </div>
        </div>
      </div>

      <div class="iuc-img-clip-op width-0 flex1">
        <div class="iuc-img-clip-default" v-if="!renderData.imgBlobUrl">
          <div class="iuc-img-clip-selectfile">
            <Button
              @click="handleSelectFile"
              :renderStyle="{
                '--padding': '0 24px',
                '--width': '100%',
                '--height': '36px',
                '--borderRadius': '50px',
                '--fontColor': '#fff',
                '--fontSize': '16px',
                '--fontWeight': 'normal',
                '--border': 'none',
                '--background': 'rgba(255, 255, 255, 0.1)',
                '--backgroundHover': 'rgba(255, 255, 255, 0.2)',
                '--fontColorHover': '#fff',
              }"
            >
              {{ $$t("create.song_publish_image_selected_btn") }}
            </Button>
          </div>
        </div>
        <div class="iuc-img-clip-edit flex" v-else>
          <div class="iuc-img-clip-edit-scale flex">
            <div class="iuc-img-clip-edit-scale-label">
              {{ $$t("common.size") }}
            </div>
            <div class="iuc-img-clip-edit-scale-slider flex1">
              <el-slider
                tooltip-class="image-upload-clip-mini-scale-slider-tooltip"
                :format-tooltip="
                  (e) => {
                    return (
                      ((e / (100 / props.maxScaleRatio)) * 100).toFixed(0) + '%'
                    );
                  }
                "
                v-model="renderData.scaleRatioValueBind"
                @input="changeScaleRatio"
                @change="changeScaleRatio"
              >
              </el-slider>
            </div>
          </div>

          <div class="iuc-img-clip-edit-delete">
            <Button
              @click="onRemoveClick"
              :renderStyle="{
                '--padding': '0 24px',
                '--width': '100%',
                '--height': '36px',
                '--borderRadius': '50px',
                '--fontColor': '#fff',
                '--fontSize': '16px',
                '--fontWeight': 'normal',
                '--border': 'none',
                '--background': 'rgba(255, 255, 255, 0.1)',
                '--backgroundHover': 'rgba(255, 255, 255, 0.2)',
                '--fontColorHover': '#fff',
              }"
            >
              <img src="@/assets/img/delete-white-ico.png" alt="delete" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  computed,
  readonly,
  reactive,
  watch,
  defineExpose,
  onMounted,
  onUpdated,
  onUnmounted,
} from "vue";
import tools from "@/utils/tools.js";
import { $$language, $$t } from "@/i18n/i18n.js";

import { formatDate } from "@/utils/dateTime.js";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
// import ImageUtils from "./imageUtils.js";
const FilePond = vueFilePond();

import ImgEmpty from "@/assets/img/img-empty.png";
import Button from "@/components/basic/button/Button.vue";
import message from "@/components/functionCallComponent/message.jsx";

const MAX_COVER_SIZE = 3000; // 大于3000的图片会缩放成 3000*3000

const refUploadContent = ref(null);
const refUpload = ref(null);
const refImgClipBox = ref(null);
const refImgClipSelectArea = ref(null);

const props = defineProps({
  renderStyle: {
    type: Object,
    default: () => {
      return {};
    },
  },
  pathKey: {
    type: String,
    default: null,
  },
  imgUrl: {
    type: String,
    default: "",
  },
  maxScaleRatio: {
    type: Number,
    default: 5,
  },
  acceptedFileTypes: {
    type: String,
    default: "image/png, image/jpeg, image/jpg",
  },
});

const defaltRenderStyle = {
  "--boxPadding": "5px 17px",
  "--boxRadius": "8px",
  "--boxBackground": "rgba(255, 255, 255, 0.1)",

  "--clipBoxWidth": "70px",
  "--clipBoxHeight": "70px",
  "--clipBoxPadding": "5px",
};

const emits = defineEmits(["saveSuccess", "urlChange", "translateChange"]);
const statusData = {
  initScaleWh: "",
  initScaleX: 1,
  initScaleY: 1,
  imgWidth: 0,
  imgHeight: 0,
};
const renderData = reactive({
  renderStyle: Object.assign({}, defaltRenderStyle, props.renderStyle ?? {}),
  isChange: false,
  bgImgBoxWidth: 0,
  bgImgBoxHeight: 0,

  imgBoxWidth: 0,
  imgBoxHeight: 0,

  files: [],
  scaleRatio: 0,
  scaleRatioValue: 0,
  scaleRatioValueBind: 0,
  imgBlobUrl: "",
  imgScaleX: 1,
  imgScaleY: 1,
  translateX: 0,
  translateY: 0,
  ifRenderFilePond: true,

  renderClass: "",
});

const onRemoveClick = () => {
  renderData.files = [];
  renderData.file = null;
  renderData.imgBlobUrl = "";
  renderData.scaleRatioValue = 0;
  renderData.scaleRatio = 0;
  emits("urlChange", false);
};

const getImageDimensions = (file, url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      // console.log(
      //   img.width,
      //   img.height,
      //   ImageUtils.getImgColorAllData(img, {
      //     centerColor: true,
      //     colorCountMap: true,
      //     colors: true,
      //     topColorsCount: 10,
      //   })
      // );
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = (error) => {
      reject("Error loading the image");
    };
    img.src = url ?? URL.createObjectURL(file);
  });
};

const changeScaleRatio = (e) => {
  renderData.scaleRatioValueBind = e;
  renderData.isChange = true;
  renderData.scaleRatioValue = (e / (100 / props.maxScaleRatio)) * 100;
  let preScaleRatio = renderData.scaleRatio;
  let changedScaleRatio = renderData.scaleRatioValue / 100;
  const { bgImgBoxWidth, bgImgBoxHeight } = renderData;

  let scaleRatio = (changedScaleRatio + 1) / (preScaleRatio + 1);
  renderData.translateX =
    (renderData.translateX - bgImgBoxWidth / 2) * scaleRatio +
    bgImgBoxWidth / 2;
  renderData.translateY =
    (renderData.translateY - bgImgBoxHeight / 2) * scaleRatio +
    bgImgBoxHeight / 2;
  // Update the scale ratio in renderData
  renderData.scaleRatio = changedScaleRatio;
  checkOutOfBounds();
  emits("translateChange", "size");
};

const initUploadClipContent = async (file, url) => {
  if (!refImgClipBox.value) {
    return;
  }
  renderData.scaleRatio = 0;
  const { width, height } = await getImageDimensions(file, url);

  const { width: boxWidth, height: boxHeight } =
    refImgClipBox.value.getBoundingClientRect();
  const { width: selectAreaWidth, height: selectAreaHeight } =
    refImgClipSelectArea.value.getBoundingClientRect();

  renderData.bgImgBoxWidth = boxWidth;
  renderData.bgImgBoxHeight = boxHeight;

  renderData.imgBoxWidth = selectAreaWidth;
  renderData.imgBoxHeight = selectAreaHeight;

  statusData.imgWidth = width;
  statusData.imgHeight = height;
  let min = 0;
  let max = 0;
  if (width > height) {
    min = height;
    max = width;

    renderData.imgScaleY = 1;
    renderData.imgScaleX = max / min;

    renderData.translateX =
      -((renderData.imgScaleX - 1) * renderData.imgBoxWidth) / 2;
    renderData.translateY = 0;

    statusData.initScaleX = renderData.imgScaleY;
    statusData.initScaleY = renderData.imgScaleX;
    statusData.initScaleWh = "height";
  } else {
    min = width;
    max = height;

    renderData.imgScaleX = 1;
    renderData.imgScaleY = max / min;

    renderData.translateX = 0;
    renderData.translateY =
      -((renderData.imgScaleY - 1) * renderData.imgBoxHeight) / 2;

    statusData.initScaleX = renderData.imgScaleY;
    statusData.initScaleY = renderData.imgScaleX;
    statusData.initScaleWh = "width";
  }

  renderData.imgBlobUrl = url ?? URL.createObjectURL(file);
  emits("urlChange", true);
};

const handleSelectFile = () => {
  refUpload.value?.$el.querySelector(".filepond--drop-label").dispatchEvent(
    new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    })
  );
};

const handleAddFile = (error, fileItem, fileList) => {
  if (error) {
    console.error("File add error:", error);
    return;
  }
  console.log("File added:", fileItem);
  handleAfterRead(fileItem.file, 2);
};

const handleAfterRead = async (e, type = 1) => {
  console.log("File handleAfterRead:", type, e);
  let file = null;
  if (e instanceof File && props.acceptedFileTypes.includes(e.type)) {
    file = e;
  } else {
    message.error({
      content:
        $$language() == "cn"
          ? "请上传图片文件"
          : "Please upload a song cover image",
      postion: "top",
    });
  }
  renderData.ifRenderFilePond = false;
  nextTick(() => {
    renderData.ifRenderFilePond = true;
    nextTick(() => {
      setTimeout(() => {
        refUpload.value?.$el
          ?.querySelector("input")
          ?.setAttribute("accept", props.acceptedFileTypes);
      }, 20);
    });
  });
  if (!file) return;
  if (file.size > 1024 * 1024 * 10) {
    message.error({
      content: $$language() == "cn" ? "最大10M" : "10MB is max",
      postion: "top",
    });
    return;
  }
  renderData.file = file;
  renderData.isChange = true;
  initUploadClipContent(file);
};

const checkOutOfBounds = () => {
  const { imgBoxWidth, imgBoxHeight, imgScaleX, imgScaleY, scaleRatio } =
    renderData;
  if (renderData.translateX >= 0) {
    renderData.translateX = 0;
  } else if (
    renderData.translateX <=
    imgBoxWidth - imgBoxWidth * imgScaleX * (1 + scaleRatio)
  ) {
    renderData.translateX =
      imgBoxWidth - imgBoxWidth * imgScaleX * (1 + scaleRatio);
  } else {
  }

  if (renderData.translateY >= 0) {
    renderData.translateY = 0;
  } else if (
    renderData.translateY <=
    imgBoxHeight - imgBoxHeight * imgScaleY * (1 + scaleRatio)
  ) {
    renderData.translateY =
      imgBoxHeight - imgBoxHeight * imgScaleY * (1 + scaleRatio);
  } else {
  }
};

const initChangeClipAreaEvent = () => {
  statusData.touchSwipeState = false;
  let startTranslateX = 0;
  let startTranslateY = 0;
  statusData.registTouchWaveBarSwipeCall = tools.registTouchSwipe(
    refImgClipSelectArea.value,
    {
      direction: "horizontal",
      startCb: (e, actionData) => {
        if (renderData.imgBlobUrl) {
          statusData.touchSwipeState = true;
          startTranslateX = renderData.translateX;
          startTranslateY = renderData.translateY;
        }
      },
      moveCb: (e, actionData) => {
        if (statusData.touchSwipeState) {
          const { startX, endX, startY, endY } = actionData;
          const dx = Math.floor(endX - startX);
          const dy = Math.floor(endY - startY);

          let transX = startTranslateX + dx;
          let transY = startTranslateY + dy;

          renderData.translateX = transX;
          renderData.translateY = transY;
          checkOutOfBounds();
        }
      },
      endCb: (e, actionData) => {
        if (statusData.touchSwipeState) {
          statusData.touchSwipeState = false;
          renderData.isChange = true;
          emits("translateChange", "showArea");
        }
      },
    }
  );
};

const base64ToBlob = (base64) => {
  const byteString = atob(base64.split(",")[1]); // 解码 base64
  const mimeType = base64.split(",")[0].match(/:(.*?);/)[1]; // 获取图片类型
  const byteNumbers = new Array(byteString.length);

  for (let i = 0; i < byteString.length; i++) {
    byteNumbers[i] = byteString.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
};

// Blob 对象的 MIME 类型对应文件后缀
const getFileExtensionFromBlob = (blob) => {
  const mimeType = blob.type;
  const mimeTypesMap = {
    "image/jpeg": "jpg",
    "image/png": "png",
    "image/gif": "gif",
    "image/svg+xml": "svg",
    "image/webp": "webp",
    "text/plain": "txt",
    "text/html": "html",
    "application/pdf": "pdf",
    "application/zip": "zip",
    "audio/mpeg": "mp3",
    "audio/wav": "wav",
    "video/mp4": "mp4",
    "video/webm": "webm",
    // 你可以扩展更多的 MIME 类型和对应的后缀
  };

  return mimeTypesMap[mimeType] || "";
};

const excueClipImage = () => {
  return new Promise((resolve, reject) => {
    if (!renderData.imgBlobUrl) {
      resolve(null);
      return;
    }
    const { imgWidth, imgHeight } = statusData;
    const { imgBoxWidth, imgBoxHeight, translateX, translateY } = renderData;

    const imgRenderWidth =
      renderData.imgBoxWidth *
      renderData.imgScaleX *
      (1 + renderData.scaleRatio);
    const imgRenderHeight =
      renderData.imgBoxHeight *
      renderData.imgScaleY *
      (1 + renderData.scaleRatio);

    const scaleRatioX = imgWidth / imgRenderWidth;
    const scaleRatioY = imgHeight / imgRenderHeight;
    try {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      let cw = Math.floor(imgBoxWidth * scaleRatioX);
      let ch = Math.floor(imgBoxHeight * scaleRatioY);

      // if (cw > MAX_COVER_SIZE) {
      //   cw = MAX_COVER_SIZE;
      //   ch = MAX_COVER_SIZE;
      // }

      canvas.width = cw;
      canvas.height = ch;

      const image = new Image();
      image.src = renderData.imgBlobUrl;

      image.onload = function () {
        ctx.fillStyle = "#FFFFFF"; // 设置填充颜色为白色
        ctx.fillRect(0, 0, canvas.width, canvas.height); // 填充整个画布
        ctx.drawImage(
          image,
          Math.floor(Math.abs(translateX * scaleRatioX)),
          Math.floor(Math.abs(translateY * scaleRatioY)),
          canvas.width,
          canvas.height,
          0,
          0,
          canvas.width,
          canvas.height
        );

        const croppedImageURL = canvas.toDataURL("image/jpeg", 1);

        // console.log(croppedImageURL);
        resolve(croppedImageURL);
      };
    } catch (e) {
      reject(e);
    }
  });
};

const initByUrl = (url) => {
  fetch(url)
    .then((response) => {
      // 确保响应状态为200 OK
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.blob(); // 将响应体转换为Blob对象
    })
    .then((blob) => {
      // 创建一个链接元素用于下载
      renderData.imgBlobUrl = window.URL.createObjectURL(blob);
      renderData.file = new File([blob], blob.type);
      initUploadClipContent(null, renderData.imgBlobUrl);
    })
    .catch((error) => {
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
    });
};
const onResize = () => {
  renderData.renderClass =
    window.innerWidth <= 768 ? "image-upload-clip-mini-mobile" : "";
};
onMounted(() => {
  setTimeout(() => {
    refUpload.value?.$el
      ?.querySelector("input")
      ?.setAttribute("accept", props.acceptedFileTypes);
  }, 20);
  initChangeClipAreaEvent();
  if (props.imgUrl) {
    initByUrl(props.imgUrl);
  }
  renderData.resizeObserver = new ResizeObserver(onResize);
  renderData.resizeObserver?.observe(document.body);
});

watch(
  () => props.imgUrl,
  (newVal) => {
    initByUrl(newVal);
  }
);

onBeforeUnmount(() => {
  statusData.registTouchWaveBarSwipeCall?.();
  renderData.resizeObserver = new ResizeObserver(onResize).observe(
    document.body
  );
});

defineExpose({
  getClipImageData: () => {
    return new Promise((resolve, reject) => {
      excueClipImage()
        .then((res) => {
          if (!res) {
            resolve(null);
            return;
          }
          const blob = base64ToBlob(res);
          const resfile = new File(
            [blob],
            `output_${formatDate(
              new Date(),
              "YYYY-MM-DD_HH:mm:ss"
            )}.${getFileExtensionFromBlob(blob)}`,
            { type: blob.type }
          );
          resolve({
            pathKey: props.pathKey,
            changed: renderData.isChange,
            file: resfile,
            base64: res,
            blob: blob,
            bloburl: URL.createObjectURL(resfile),
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
});
</script>
<style lang="scss">
.image-upload-clip-mini {
  width: 100%;
  height: 100%;
  border-radius: var(--boxRadius);
  padding: var(--boxPadding);
  background: var(--boxBackground);
  .filepond--root {
    height: var(--filepondHeight, 0) !important;
    background: transparent;
    box-shadow: none;
    border-radius: var(--filepondHeight, 0);
    align-items: center;
    justify-content: center;
    border-radius: var(--filepondHeight, 0);
    margin: 0;
    .filepond--wrapper {
      height: 100% !important;
      border-radius: var(--filepondHeight, 0);
    }
    .filepond--browser.filepond--browser {
      height: 0;
      padding: 0;
    }
    .filepond--list-scroller {
      display: none;
    }
    .filepond--credits {
      display: none;
    }

    .filepond--panel-root {
      border-radius: 0.5em;
      background: transparent;
      box-shadow: none;
      border-radius: var(--filepondHeight, 0);
      display: none;
    }

    .filepond--assistant {
      display: block;
    }
    .filepond--drop-label {
      display: block;
      visibility: visible !important;
      transform: translate3d(0px, 0, 0px) !important;
      height: var(--filepondHeight, 0) !important;
      border-radius: var(--filepondHeight, 0) !important;
      min-height: var(--filepondHeight, 0) !important;
      opacity: 1 !important;
      pointer-events: auto !important;
      & > label {
        width: 0;
        height: 0;
        overflow: hidden;
        padding: 0;
      }
    }
    &[data-hopper-state="drag-over"] {
      .add-file-or-link-main {
        border: 1px dashed #94adff;
      }
    }
  }
  .iuc-content {
    align-items: center;
  }
  .iuc-img-clip-box {
    width: var(--clipBoxWidth);
    height: var(--clipBoxHeight);
    position: relative;
    background: rgba(0, 0, 0, 0);
    border-radius: 8px;
    overflow: hidden;
    .iuc-img-clip-select-area {
      background: rgba(0, 0, 0, 0.15);
      width: 100%;
      height: 100%;
      border-radius: 8px;
      overflow: hidden;

      cursor: grab;
    }

    .iuc-img-clip-bg {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      opacity: 0.15;
      overflow: hidden;
      padding: var(--clipBoxPadding);
    }
    .iuc-img-clip-main {
      padding: var(--clipBoxPadding);
      width: 100%;
      height: 100%;
      border-radius: 8px;
      position: absolute;
      top: 0;
      left: 0;
    }
    .iuc-img-render {
      background-repeat: no-repeat;
      transform-origin: top left;
    }
  }

  .iuc-img-clip-op {
    margin: 0 0 0 24px;
    .iuc-img-clip-default {
    }
    .iuc-img-clip-selectfile {
      margin: 0;
      max-width: 300px;
    }

    .iuc-img-clip-edit-scale {
      width: 188px;
      height: 36px;
      border-radius: 50px;
      padding: 10px 24px;
      background: rgba(255, 255, 255, 0.1);
      align-items: center;
      .iuc-img-clip-edit-scale-label {
        font-size: 16px;
        font-weight: normal;
        line-height: 16px;
        color: #ffffff;
        margin: 0 12px 0 0;
      }
      .el-slider {
        height: unset;
        .el-slider__runway {
          height: 3px;
          background: rgba(255, 255, 255);
        }
        .el-slider__bar {
          height: 3px;
          background: #fff;
        }
        .el-slider__button-wrapper {
          width: 20px;
          height: 20px;
          top: -8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .el-slider__button {
          width: 12px;
          height: 12px;
          opacity: 1;
          border: none;
          background: #ffffff;
          display: block;
        }
      }
    }
    .iuc-img-clip-edit-delete {
      margin: 0 0 0 8px;
      img {
        height: 24px;
        width: 24px;
      }
    }
  }
  &.image-upload-clip-mini-mobile {
    padding: 14px;
    .iuc-content {
      flex-direction: column;
    }
    .iuc-img-clip-op {
      flex: unset;
      width: max-content;
      max-width: 100%;
      margin: 20px 0 0 0;
    }
    .iuc-img-clip-default {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .iuc-img-clip-text {
      text-align: center;
    }
    .iuc-img-clip-selectfile {
      margin: 0 auto;
    }
  }
}

.el-popper.is-dark.image-upload-clip-mini-scale-slider-tooltip-mobile {
  margin: auto 0 -5px 0 !important;
}
</style>
