const mediaUtil = {
  // 分秒转化
  formatTime(secs) {
    if (isNaN(Number(secs))) {
      return '00:00';
    }
    secs = Math.ceil(secs);
    const minutes = Math.floor(secs / 60) || 0;
    const seconds = Math.ceil(secs - minutes * 60) || 0;
    return `${String(minutes).padStart(2, 0)}:${String(seconds).padStart(2, 0)}`;
  },
  formatTimeMs(secs) {
    if (isNaN(Number(secs))) {
      return '00:00.0';
    }

    secs = parseFloat(secs); // 支持小数秒
    const hours = Math.floor(secs / 3600) || 0; // 计算小时
    const minutes = Math.floor((secs % 3600) / 60) || 0; // 计算分钟
    const seconds = Math.floor(secs % 60) || 0; // 计算秒
    const milliseconds = Math.round((secs - Math.floor(secs)) * 10) || 0; // 计算毫秒（取1位小数）

    // 如果有小时，则显示 hh:mm:ss.s，否则显示 mm:ss.s
    if (hours > 0) {
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}.${String(milliseconds)}`;
    } else {
      return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}.${String(milliseconds)}`;
    }
  },
  formatProgressToTime(progress, duration) {
    return mediaUtil.formatTime(Math.floor(progress / 100 * duration));
  },
  trimTo(val, num) {
    var nums = { '0': 1, '1': 10, '2': 100, '3': 1000, '4': 10000, '5': 100000 };
    var dec = nums[num];
    return ((val * dec) >> 0) / dec;
  },
  drawEqualizer({ analyser, audioContext, canvas, canvasContext, isplay, num = 24, color }) {
    const cw = canvas.width;
    const ch = canvas.height;
    if (isplay == false) {
      canvasContext.clearRect(0, 0, cw, ch);
    }
    if (!analyser) return;
    try {
      //analyser.frequencyBinCount 实时得到的音频频域的数据的个数为fftSize的一半
      //定义一个Uint8Array数组，大小为analyser.frequencyBinCount
      //计算出采样频率44100所需的缓冲区长度
      let length = analyser.frequencyBinCount * 44100 / audioContext.sampleRate | 0;
      let arr = new Uint8Array(length);

      //getByteFrequencyData()复制音频当前的频域数据（数量是frequencyBinCount）到 （8位无符号整形类型化数组）中
      analyser.getByteFrequencyData(arr);
      let l0 = 0;
      let r0 = 0;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] > 0 && l0 == 0) {
          l0 = i;
        }
        if (arr[length - i - 1] > 0 && r0 == 0) {
          r0 = length - i - 1
        }
        if (l0 != 0 && r0 != 0) {
          break;
        }
      }
      arr = arr.slice(l0, r0);
      //console.log(arr);

      //canvas的2D pixi.js库的时候，其动画的刷新都用requestAnimationFrame替代了setTimeout 或 setInterval
      //它可以帮你以60fps的帧率绘制动画。
      let step = Math.floor(arr.length / num);

      let cStepv = Math.floor((cw) / num);

      let intervar = 4;

      let cStepW = cStepv - 4;

      canvasContext.clearRect(0, 0, cw, ch);
      const positionY = ch;

      canvasContext.beginPath();
      for (let i = 0; i <= num; i++) {
        let value = Math.floor(arr[step * i] / 100 * positionY / 4);
        canvasContext.fillStyle = color ?? "#ffffff80";
        //由画布中间向两边画矩形
        canvasContext.fillRect(cw * 0.5 - intervar - (i + 1) * cStepv, positionY, cStepW, -value);//左边
        canvasContext.fillRect(cw * 0.5 - intervar + i * cStepv, positionY, cStepW, -value);//右边
        canvasContext.fill();//fill() 方法填充当前的图像（路径）。默认颜色是黑色
      }
    } catch (e) {
      console.log(e);
    }
    // 调用
    // <canvas ref="refSpectrogCanvas"></canvas>
    // const refSpectrogCanvas = ref(null)
    // let canvasContext;
    // const drawEqualizer =(analyser, audioContext, isplay) => {
    //   mediaUtils.drawEqualizer({
    //     analyser,
    //     audioContext,
    //     isplay,
    //     canvas: refSpectrogCanvas.value,
    //     canvasContext: canvasContext ?? refSpectrogCanvas.value.getContext("2d"),
    //   });
    // }
  }
};

export default mediaUtil;
