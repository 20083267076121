import { createI18n } from "vue-i18n";
import GlobalConfig from "@/config/config.js";
import { getDataByFields } from "@/utils/tools.js";

import commonEn from "./en/common.json";
// import commonCn from "./cn/common.json";
import commonJa from "./ja/common.json";
import commonKo from "./ko/common.json";
import commonSp from "./sp/common.json";
import commonPo from "./po/common.json";
import commonZHCN from "./zh-CN/common.json";
import commonZHTW from "./zh-TW/common.json";
import commonDE from "./de/common.json";
import commonFR from "./fr/common.json";
import commonIT from "./it/common.json";
import commonRU from "./ru/common.json";


import storeEn from "./en/store.json";
// import storeCn from "./cn/store.json";
import storeJa from "./ja/store.json";
import storeKo from "./ko/store.json";
import storeSp from "./sp/store.json";
import storePo from "./po/store.json";
import storeZHCN from "./zh-CN/store.json";
import storeZHTW from "./zh-TW/store.json";
import storeDE from "./de/store.json";
import storeFR from "./fr/store.json";
import storeIT from "./it/store.json";
import storeRU from "./ru/store.json";


import createEn from "./en/create.json";
// import createCn from "./cn/create.json";
import createJa from "./ja/create.json";
import createKo from "./ko/create.json";
import createSp from "./sp/create.json";
import createPo from "./po/create.json";
import createZHCN from "./zh-CN/create.json";
import createZHTW from "./zh-TW/create.json";
import createDE from "./de/create.json";
import createFR from "./fr/create.json";
import createIT from "./it/create.json";
import createRU from "./ru/create.json";

import creditEn from "./en/credit.json";
// import creditCn from "./cn/credit.json";
import creditJa from "./ja/credit.json";
import creditKo from "./ko/credit.json";
import creditSp from "./sp/credit.json";
import creditPo from "./po/credit.json";
import creditZHCN from "./zh-CN/credit.json";
import creditZHTW from "./zh-TW/credit.json";
import creditDE from "./de/credit.json";
import creditFR from "./fr/credit.json";
import creditIT from "./it/credit.json";
import creditRU from "./ru/credit.json";

// import libraryCn from "./cn/library.json";
import libraryEn from "./en/library.json";
import libraryJa from "./ja/library.json";
import libraryKo from "./ko/library.json";
import librarySp from "./sp/library.json";
import libraryPo from "./po/library.json";
import libraryZHCN from "./zh-CN/library.json";
import libraryZHTW from "./zh-TW/library.json";
import libraryDE from "./de/library.json";
import libraryFR from "./fr/library.json";
import libraryIT from "./it/library.json";
import libraryRU from "./ru/library.json";

// import mineCn from "./cn/mine.json";
import mineEn from "./en/mine.json";
import mineJa from "./ja/mine.json";
import mineKo from "./ko/mine.json";
import mineSp from "./sp/mine.json";
import minePo from "./po/mine.json";
import mineZHCN from "./zh-CN/mine.json";
import mineZHTW from "./zh-TW/mine.json";
import mineDE from "./de/mine.json";
import mineFR from "./fr/mine.json";
import mineIT from "./it/mine.json";
import mineRU from "./ru/mine.json";

// import landCn from "./cn/land.json";
import landEn from "./en/land.json";
import landJa from "./ja/land.json";
import landKo from "./ko/land.json";
import landSp from "./sp/land.json";
import landPo from "./po/land.json";
import landZHCN from "./zh-CN/land.json";
import landZHTW from "./zh-TW/land.json";
import landDE from "./de/land.json";
import landFR from "./fr/land.json";
import landIT from "./it/land.json";
import landRU from "./ru/land.json";

// import loginCn from "./cn/login.json";
import loginEn from "./en/login.json";
import loginJa from "./ja/login.json";
import loginKo from "./ko/login.json";
import loginSp from "./sp/login.json";
import loginPo from "./po/login.json";
import loginZHCN from "./zh-CN/login.json";
import loginDE from "./de/login.json";
import loginFR from "./fr/login.json";
import loginIT from "./it/login.json";
import loginRU from "./ru/login.json";

import paymentEn from "./en/payment.json";
import paymentJa from "./ja/payment.json";
import paymentKo from "./ko/payment.json";
import paymentSp from "./sp/payment.json";
import paymentPo from "./po/payment.json";
import paymentZHCN from "./zh-CN/payment.json";
import paymentZHTW from "./zh-TW/payment.json";
import paymentDE from "./de/payment.json";
import paymentFR from "./fr/payment.json";
import paymentIT from "./it/payment.json";
import paymentRU from "./ru/payment.json";

import editorEn from "./en/editor.json";
import editorJa from "./ja/editor.json";
import editorKo from "./ko/editor.json";
import editorSp from "./sp/editor.json";
import editorPo from "./po/editor.json";
import editorZHCN from "./zh-CN/editor.json";
import editorZHTW from "./zh-TW/editor.json";
import editorDE from "./de/editor.json";
import editorFR from "./fr/editor.json";
import editorIT from "./it/editor.json";
import editorRU from "./ru/editor.json";

const messages = {
  // cn: {
  //   // 中文
  //   common: commonCn,
  //   store: storeCn,
  //   create: createCn,
  //   credit: creditCn,
  //   mine: mineCn,
  //   library: libraryCn,
  //   land: landCn,
  //   login: loginCn
  // },
  en: {
    common: commonEn,
    store: storeEn,
    create: createEn,
    credit: creditEn,
    mine: mineEn,
    library: libraryEn,
    land: landEn,
    login: loginEn,
    payment: paymentEn,
    editor: editorEn,
  },
  ja: {
    common: commonJa,
    store: storeJa,
    create: createJa,
    credit: creditJa,
    mine: mineJa,
    library: libraryJa,
    land: landJa,
    login: loginJa,
    payment: paymentJa,
    editor: editorJa,
  },
  ko: {
    // 韩语
    common: commonKo,
    store: storeKo,
    create: createKo,
    credit: creditKo,
    mine: mineKo,
    library: libraryKo,
    land: landKo,
    login: loginKo,
    payment: paymentKo,
    editor: editorKo,
  },
  es: {
    // 西班牙语
    common: commonSp,
    store: storeSp,
    create: createSp,
    credit: creditSp,
    mine: mineSp,
    library: librarySp,
    land: landSp,
    login: loginSp,
    payment: paymentSp,
    editor: editorSp,
  },
  pt: {
    // 葡萄牙语
    common: commonPo,
    store: storePo,
    create: createPo,
    credit: creditPo,
    mine: minePo,
    library: libraryPo,
    land: landPo,
    login: loginPo,
    payment: paymentPo,
    editor: editorPo,
  },
  "zh-CN": {
    common: commonZHCN,
    store: storeZHCN,
    create: createZHCN,
    credit: creditZHCN,
    mine: mineZHCN,
    library: libraryZHCN,
    land: landZHCN,
    login: loginZHCN,
    payment: paymentZHCN,
    editor: editorZHCN,
  },
  "zh-TW": {
    common: commonZHTW,
    store: storeZHTW,
    create: createZHTW,
    credit: creditZHTW,
    mine: mineZHTW,
    library: libraryZHTW,
    land: landZHTW,
    login: loginZHCN,
    payment: paymentZHTW,
    editor: editorZHTW,
  },
  de: {
    common: commonDE,
    store: storeDE,
    create: createDE,
    credit: creditDE,
    mine: mineDE,
    library: libraryDE,
    land: landDE,
    login: loginDE,
    payment: paymentDE,
    editor: editorDE,
  },
  fr: {
    common: commonFR,
    store: storeFR,
    create: createFR,
    credit: creditFR,
    mine: mineFR,
    library: libraryFR,
    land: landFR,
    login: loginFR,
    payment: paymentFR,
    editor: editorFR,
  },
  it: {
    common: commonIT,
    store: storeIT,
    create: createIT,
    credit: creditIT,
    mine: mineIT,
    library: libraryIT,
    land: landIT,
    login: loginIT,
    payment: paymentIT,
    editor: editorIT,
  },
  ru: {
    common: commonRU,
    store: storeRU,
    create: createRU,
    credit: creditRU,
    mine: mineRU,
    library: libraryRU,
    land: landRU,
    login: loginRU,
    payment: paymentRU,
    editor: editorRU,
  }
};

export const mapBrowserLanguage = (appLanguage) => {
  // 获取浏览器的首选语言
  const browserLanguage = appLanguage || navigator.language || navigator.userLanguage;
  if (!GlobalConfig.switchLanguage) return "en";

  // 定义语言映射规则
  const languageMap = {
    en: ["en", "en-US", "en-GB", "en-AU", "en-CA"], // 英语
    'zh-CN': ["zh", "zh-CN", "zh-Hans", "zh-Hant"],// 中文简体
    'zh-TW': [, "zh-HK", "zh-MO", "zh-SG", "zh-TW"], // 中文繁体
    ja: ["ja", "ja-JP"],                            // 日语
    ko: ["ko", "ko-KR"],                            // 韩语
    es: ["es", "es-ES", "es-MX", "es-419"],         // 西班牙语
    pt: ["pt", "pt-BR", "pt-PT"],                   // 葡萄牙语
    de: ["de", "de-DE"],                            // 德语
    fr: ["fr", "fr-FR"],                            // 法语
    it: ["it", "it-IT"],                            // 意大利语
    ru: ["ru", "ru-RU"],                            // 俄语
  };

  // 遍历映射规则，找到最靠近的语言
  for (const [key, values] of Object.entries(languageMap)) {
    if (values.includes(browserLanguage)) {
      return key; // 返回映射的语言代码
    }
  }

  // 如果没有匹配到，返回默认值 (如 "en")
  return "en";
}

const localData =
  localStorage.getItem(GlobalConfig.localStorageGlobalKey) || "{}";
const { appLanguage } = JSON.parse(localData);

const language = import.meta.env.VITE_LANGUAGE;
console.log("VITE_LANGUAGE", language);
const i18n = createI18n({
  locale: mapBrowserLanguage(appLanguage), // language ?? "en",
  //appLanguage ?? "en", // 默认语言
  fallbackLocale: "en", // 如果找不到当前语言的翻译，默认回退到的语言
  messages,
});

export const $$t = (key, language, params) => {
  if (language && Object.keys(messages).includes(language)) {
    return getDataByFields(messages[language], key, params);
  } else {
    return i18n.global.t(key, params);
  }
};

export const $$tValue = (key, params) => {  // 替换‘text{key}’ ,{key:value}格式
  return i18n.global.t(key, params);
}

export const $$language = (language) => {
  if (language && Object.keys(messages).includes(language)) {
    if (i18n.global.locale != language) {
      let old = i18n.global.locale;
      i18n.global.locale = language;
      setTimeout(() => {
        triggleBrodcastEvent("languageChange", language, old);
      }, 1);
    }
  }
  return i18n.global.locale;
};

const languageChangeListeners = [];
const triggleBrodcastEvent = (type, language) => {
  languageChangeListeners.forEach((listener) => {
    listener(type, language);
  });
};

export const $$registLanguageChangeListener = (listener) => {
  languageChangeListeners.push(listener);
};

export const $$removeLanguageChangeListener = (listener) => {
  const index = languageChangeListeners.indexOf(listener);
  if (index > -1) {
    languageChangeListeners.splice(index, 1);
  }
};
export const LanguageList = [
  {
    key: "en",
    label: "English",
  },
  {
    key: "zh-CN",
    label: "中文（简体）",
  },
  {
    key: "zh-TW",
    label: "中文（繁體）", // 或 "繁體中文"
  },
  {
    key: "ja",
    label: "日本語",
  },
  {
    key: "ko",
    label: "한국어", // 或 "한국어 (Korean)"
  },
  {
    key: "es",
    label: "Español", // 或保持 "Español"
  },
  {
    key: "pt",
    label: "Português",
  },
  {
    key: "de",
    label: "Deutsch",
  },
  {
    key: "fr",
    label: "Français", // 首字母大写
  },
  {
    key: "it",
    label: "Italiano",
  },
  {
    key: "ru",
    label: "Русский язык", // 或保持 "Русский язык"
  },
]


export const LanguageListMap = {
  // cn: [
  //   {
  //     key: "cn",
  //     label: "中文",
  //   },
  //   {
  //     key: "en",
  //     label: "英语",
  //   },
  //   {
  //     key: "ja",
  //     label: "日语",
  //   },
  //   {
  //     key: "ko",
  //     label: "韩语",
  //   },
  //   {
  //     key: "es",
  //     label: "西班牙语",
  //   },
  //   {
  //     key: "pt",
  //     label: "葡萄牙语",
  //   },
  // ],
  en: [
    // {
    //   key: "cn",
    //   label: "Chinese",
    // },
    {
      key: "en",
      label: "English",
    },
    {
      key: "ja",
      label: "Japanese",
    },
    {
      key: "ko",
      label: "Korean",
    },
    {
      key: "es",
      label: "Spanish",
    },
    {
      key: "pt",
      label: "Portuguese",
    },
  ],
  ja: [
    // {
    //   key: "cn",
    //   label: "中国語",
    // },
    {
      key: "en",
      label: "英語",
    },

    {
      key: "ja",
      label: "日本語",
    },
    {
      key: "ko",
      label: "韓国語",
    },
    {
      key: "es",
      label: "スペイン語",
    },
    {
      key: "pt",
      label: "ポルトガル語",
    },
  ],
  ko: [
    // {
    //   key: "cn",
    //   label: "중국어",
    // },
    {
      key: "en",
      label: "영어",
    },
    {
      key: "ja",
      label: "일본어",
    },
    {
      key: "ko",
      label: "한국어",
    },
    {
      key: "es",
      label: "스페인어",
    },
    {
      key: "pt",
      label: "포르투갈어",
    },
  ],
  es: [
    // {
    //   key: "cn",
    //   label: "Chino.",
    // },
    {
      key: "en",
      label: "Inglés",
    },
    {
      key: "ja",
      label: "japonés",
    },
    {
      key: "ko",
      label: "Coreano",
    },

    {
      key: "es",
      label: "Español",
    },
    {
      key: "pt",
      label: "Portugués",
    },
  ],
  pt: [
    // {
    //   key: "cn",
    //   label: "Chinês",
    // },
    {
      key: "en",
      label: "Inglês",
    },
    {
      key: "ja",
      label: "Japonês",
    },
    {
      key: "ko",
      label: "Coreano",
    },
    {
      key: "es",
      label: "Espanhol",
    },
    {
      key: "pt",
      label: "Português",
    },
  ],
};

export default i18n;
