<template>
  <div class="audio-player height-all" ref="refAudioBox" :style="renderStyle">
    <slot>
      <div
        class="audio-player-opbox height-all flex-column"
        :class="`render-disabled--${
          props.disabled
        } render-disabled-extend--${!props.opClickCallBack}`"
      >
        <div
          class="audio-player-title audio-player-title-top olh tl"
          v-if="props.title && props.titlePosition == 'topLeft'"
        >
          {{ props.title }}
        </div>
        <div class="audio-player flex flex1 height-0">
          <!-- <Bubble content="Play Previous" position="top"> -->
          <div
            class="audio-player-op-switch audio-player-op-switch-prev pointer flex-center"
            v-if="props.switch"
            @click="
              () => {
                doSwitch('prev');
              }
            "
          >
            <i></i>
          </div>
          <!-- </Bubble> -->
          <!-- <Bubble
            :content="renderData.isPlaying ? 'Pause' : 'Play'"
            position="top"
          > -->
          <div
            class="audio-player-op btn"
            @click="
              () => {
                changeOp();
              }
            "
          >
            <LoaderCircle
              :loading="renderData.isPlaying && renderData.isLoading"
              :size="renderData.loaderSize"
              :color="renderStyle['--opLoadingColor']"
              :marginSize="2"
              :strokeWidth="2"
            >
              <div class="apo pointer" v-show="!renderData.isPlaying">
                <i
                  class="block icon-play"
                  :style="{
                    background: `url(${
                      props.theme == 'dark' ? playIcoDark : playIcoLight
                    })`,
                    backgroundSize: 'cover',
                  }"
                >
                </i>
              </div>
              <div class="apo pointer" v-show="renderData.isPlaying">
                <i
                  class="block icon-pause"
                  :style="{
                    background: `url(${
                      props.theme == 'dark' ? pauseIcoDark : pauseIcoLight
                    })`,
                    backgroundSize: 'cover',
                  }"
                >
                </i>
              </div>
              <!-- <div class="apo pointer" v-show="renderData.isPlaying&&renderData.isLoading">
                  <i class="block icon-loading rotate-loading">
                  </i>
                </div> -->
            </LoaderCircle>
          </div>
          <!-- </Bubble> -->
          <!-- <Bubble content="Play next" position="top"> -->
          <div
            class="audio-player-op-switch audio-player-op-switch-next pointer flex-center"
            v-if="props.switch"
            @click="
              () => {
                doSwitch('next');
              }
            "
          >
            <i></i>
          </div>
          <!-- </Bubble> -->
          <div class="audio-player-time tr">
            <span>{{ renderData.startTime }}</span>
          </div>
          <div class="audio-player-center flex1">
            <div
              class="audio-player-title olh tl"
              v-if="props.title && props.titlePosition == 'centerTopLeft'"
            >
              {{ props.title }}
            </div>
            <div
              class="audio-player-slider"
              :class="{
                loading: renderData.isLoading,
              }"
              :style="{
                '--highPointerColor':
                  props.highPosition === null ||
                  props.highPosition == undefined ||
                  props.highPosition > renderData.duration
                    ? 'none'
                    : renderStyle['--progressbarColor'],
                '--highPointerLeft': getHighPositionPosition(),
              }"
              data-scrollbox
            >
              <vue-slider
                v-model="renderData.progress"
                @change="changeProgress"
                @drag-start="dragStart"
                @drag-end="dragEnd"
                @dragging="dragging"
                tooltip="none"
                :tooltipFormatter="formatTooltip"
              ></vue-slider>
              <div
                v-if="props.highPosition != null"
                class="audio-player-slider-highlight-pointer"
                :style="{}"
              ></div>
            </div>
          </div>
          <div class="audio-player-time tl">
            <i
              class="block icon-loading rotate-loading"
              v-if="renderData.isGenerating"
            ></i>
            <span v-else>{{
              renderData.endTime ?? renderData.duration / 1000
            }}</span>
          </div>
        </div>
      </div>
    </slot>
  </div>
</template>
<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  watch,
  defineExpose,
  onMounted,
  onUnmounted,
  reactive,
} from "vue";
import globalAudioPlayer, {
  defaultPersonalizedConfig,
  lastPerContinuousPlaybackTimeData,
} from "./GlobalAudioPlayer.js";
import playIcoDark from "@/assets/img/play-web-ico.png";
import playIcoLight from "@/assets/img/play-light-ico.png";
import pauseIcoDark from "@/assets/img/pause-web-ico.png";
import pauseIcoLight from "@/assets/img/pause-light-ico.png";
import Bubble from "@/components/bubble/bubble.vue";

import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

import mediaUtil from "../utils/utils.js";

const props = defineProps({
  renderStyle: Object,
  theme: {
    type: String,
    default: "dark", // light
  },
  titlePosition: {
    type: String,
    default: "topLeft", // centerTopLeft
  },
  title: String,

  volumeOffset: {
    type: Number,
    default: defaultPersonalizedConfig.volumeOffset,
  },
  url: {
    type: String,
    default: "",
  },
  duration: {
    type: Number,
    default: 0,
  },

  from: {
    type: Number,
    default: 0,
  },
  to: {
    type: Number,
    default: 0,
  },
  loop: {
    type: Boolean,
    default: defaultPersonalizedConfig.loop,
  },
  autoToStartIfNotCurrent: {
    type: Boolean,
    default: defaultPersonalizedConfig.autoToStartIfNotCurrent,
  },
  canSeekNotCurrent: {
    type: Boolean,
    default: defaultPersonalizedConfig.canSeekNotCurrent,
  },

  disabled: {
    type: Boolean,
    default: false,
  },
  opClickCallBack: {
    type: Function,
    default: null,
  },
  analyser: Boolean,
  switch: {
    type: Boolean,
    default: false,
  },
  highPosition: {
    type: Number,
    default: null,
  },
  isGenerating: {
    type: Boolean,
    default: false,
  },
  perContinuousPlaybackTimeCallback: {
    type: Function,
    default: () => {},
  },
  type: {
    type: String,
    default: "audio",
  },
});
const renderDefaultStyleMap = {
  dark: {
    "--opLoadingColor": "#000",
    "--opSize": "36px",
    "--opIcoSize": "24px",
    "--opBgColor": "transparent",
    "--opBgHover": "rgba(0,0,0,0.1)",
    "--opMargin": "0",

    "--opSwitchSize": "36px",
    "--opSwitchIcoSize": "20px",
    "--opSwitchBgHover": "rgba(0,0,0,0.1)",

    "--titleColor": "#000",
    "--titleFontSize": "14px",
    "--titleFontWeight": "500",

    "--timeColor": "#000",
    "--timeFontSize": "12px",
    "--timeFontWeight": "400",
    "--timeMinWidth": "38px",

    "--seekbarMargin": "0px 20px 0 10px",
    "--barColor": "rgba(0, 0, 0,  0.2)",
    "--barHeight": "2px",

    "--progressbarColor": "#000",
    "--progressSliderSize": "10px",
    "--progressSliderColor": "#000",
    "--progressSliderHoverColor": "rgba(0,0,0, 0.3)",
  },
  light: {
    "--opLoadingColor": "#fff",
    "--opSize": "36px",
    "--opBgColor": "transparent",
    "--opIcoSize": "24px",
    "--opBgHover": "rgba(0,0,0,0.1)",
    "--opMargin": "0 14px",

    "--opSwitchSize": "38px",
    "--opSwitchIcoSize": "20px",
    "--opSwitchBgHover": "rgba(0,0,0,0.1)",

    "--titleColor": "#fff",
    "--titleFontSize": "14px",
    "--titleFontWeight": "500",

    "--timeColor": "#fff",
    "--timeFontSize": "12px",
    "--timeFontWeight": "400",
    "--timeMinWidth": "36px",

    "--barColor": "rgba(255, 255, 255,  0.2)",
    "--barHeight": "2px",

    "--seekbarMargin": "0px 10px 0 10px",
    "--progressbarColor": "#fff",
    "--progressSliderSize": "10px",
    "--progressSliderColor": "#fff",
    "--progressSliderHoverColor": "rgba(255, 255, 255, 0.3)",
  },
};
const renderStyle = Object.assign(
  {},
  renderDefaultStyleMap[props.theme] || renderDefaultStyleMap["dark"],
  props.renderStyle ?? {}
);

const emits = defineEmits([
  "log",
  "drawEqualizer",
  "syncDuration",
  "syncPlayState",
  "syncLoadingState",
  "syncProgress",
  "switch",
  "curPlayEnded",
  "update:isGenerating",
]);
const isReady = ref(false); // 是否加载完成，可以开始播放
const isChangeProgress = ref(false);

const refAudioBox = ref(null);

const getLoadingSize = () => {
  return Number(renderStyle["--opSize"].replace("px", ""));
};
const stateData = {};

const renderData = reactive({
  url: props.url,
  currentTime: 0,
  startTime: "00:00",
  endTime: "00:00",
  bufferTime: "00:00",
  duration: props.duration,
  progress: 0,
  isPlaying: false,
  isLoading: false,
  isReady: false,
  loaderSize: getLoadingSize(),
  isGenerating: false,
  ifRendderSlide: true,
});
const getHighPositionPosition = () => {
  return (props.highPosition ?? 0) > (renderData.duration || 1)
    ? 100
    : ((props.highPosition ?? 0) / (renderData.duration || 1)) * 100 + "%";
};
const formatTooltip = () => {
  return mediaUtil.formatProgressToTime(
    renderData.progress,
    renderData.duration
  );
};

const analyzeStateData = {
  startPosition: 0,
};

// play or pause
const changeOp = (isplay) => {
  if (props.opClickCallBack) {
    props.opClickCallBack();
  }
  if (props.disabled) {
    globalAudioPlayer.pause(renderData.url, true);
    return;
  }
  const isPlaying =
    typeof isplay === "boolean" ? isplay : !renderData.isPlaying;
  if (isPlaying) {
    globalAudioPlayer.play(renderData.url, true);
  } else {
    globalAudioPlayer.pause(renderData.url);
  }
};

const doSwitch = (data) => {
  changeOp(false);
  emits("switch", data);
};

// var playStateBeforeSeek = undefined;

const emitsPerContinuousPlaybackTimeCallback = () => {
  if (
    lastPerContinuousPlaybackTimeData.url == renderData.url &&
    lastPerContinuousPlaybackTimeData.startPosition ==
      analyzeStateData.startPosition &&
    lastPerContinuousPlaybackTimeData.time ==
      renderData.progressCp * renderData.duration -
        analyzeStateData.startPosition
  ) {
    return;
  }
  if (
    renderData.progressCp * renderData.duration -
      analyzeStateData.startPosition >
    0
  ) {
    const data = {
      duration: renderData.duration,
      startPosition: analyzeStateData.startPosition,
      url: renderData.url,
      time:
        renderData.progressCp * renderData.duration -
        analyzeStateData.startPosition,
      endPosition: renderData.progressCp * renderData.duration,
    };
    // console.log(
    //   "emitsPerContinuousPlaybackTimeCallback",
    //   data,
    //   lastPerContinuousPlaybackTimeData
    // );
    if (props.type == "globalPlayer") {
      props.perContinuousPlaybackTimeCallback?.(data);
      Object.assign(lastPerContinuousPlaybackTimeData, data);
    }
  }
};

const changeProgress = (value) => {
  stateData.isChangeProgress = false;

  emitsPerContinuousPlaybackTimeCallback();

  renderData.progress = Math.min(100, Math.max(0, value));
  renderData.progressCp = renderData.progress / 100;
  renderData.startTime = mediaUtil.formatProgressToTime(
    value,
    renderData.duration
  );
  globalAudioPlayer.seek(renderData.url, renderData.progress / 100);
  touchEnd();

  analyzeStateData.startPosition = renderData.duration * renderData.progressCp;
};

const dragStart = () => {
  stateData.isChangeProgress = true;
};

const dragEnd = () => {
  stateData.isChangeProgress = false;
};

const dragging = (value) => {
  renderData.progress = Math.min(100, Math.max(0, value));
  renderData.startTime = mediaUtil.formatProgressToTime(
    value,
    renderData.duration
  );
};

const init = () => {
  if (!renderData.url) {
    return;
  }
  stateData.curPlayerData = globalAudioPlayer.registerPlayUrl(renderData.url, {
    duration: props.duration ?? 0,
    clipStart: props.from,
    clipEnd: props.to,
    personalizedConfig: {
      loop: props.loop,
      autoToStartIfNotCurrent: props.autoToStartIfNotCurrent,
      canSeekNotCurrent: props.canSeekNotCurrent,
    },
    listeners: {
      onDurationChange({
        duration,
        durationTime,
        sourceDuration,
        sourceDurationTime,
      }) {
        if (renderData.url == stateData.curPlayerData?.player?.url) {
          renderData.duration = duration;
          renderData.endTime = durationTime;
          if (
            renderData.isGenerating &&
            globalAudioPlayer.audio?.duration == duration &&
            duration > 0 &&
            renderData.url == globalAudioPlayer.currentUrl
          ) {
            renderData.isGenerating = false;
          }
        }
      },
      onPlayStateChange(isPlaying, eventType) {
        if (renderData.url == stateData.curPlayerData?.player?.url) {
          renderData.isPlaying = isPlaying;
          if (isPlaying) {
            analyzeStateData.startPosition =
              renderData.progressCp * renderData.duration;
          } else {
            emitsPerContinuousPlaybackTimeCallback();
            analyzeStateData.startPosition =
              renderData.progressCp * renderData.duration;
          }
        }
        // console.log("🍎🍎🍎🍎🍎", renderData.isPlaying, renderData.url);
      },
      onLoadingStateChange(isLoading) {
        if (renderData.url == stateData.curPlayerData?.player?.url) {
          renderData.isLoading = isLoading;
        }
      },
      onPositionChange: (obj) => {
        if (renderData.url == stateData.curPlayerData?.player?.url) {
          const { progress, currentTime, endTime, current } = obj;
          if (!stateData.isChangeProgress) {
            renderData.startTime = currentTime;
            renderData.endTime = endTime;
            renderData.progress = Math.min(100, Math.max(0, progress * 100));
            renderData.currentTime = current;
          }
          renderData.progressCp = progress;
        }
      },
    },
  });
  globalAudioPlayer.registGlobleListener(
    "curPlayEnded",
    ({ currentUrl, player }) => {
      if (renderData.url == currentUrl || !renderData.url) {
        emits("curPlayEnded", { currentUrl, player });
        emitsPerContinuousPlaybackTimeCallback();
        analyzeStateData.startPosition = 0;
      }
    }
  );
  if (
    stateData.curPlayerData?.player &&
    stateData.curPlayerData?.player?.url == globalAudioPlayer.currentUrl
  ) {
    renderData.isPlaying = stateData.curPlayerData?.player?.isPlaying;
    const { progress, currentTime, endTime } =
      globalAudioPlayer.getPositionData(stateData.curPlayerData?.player);
    const { duration } = globalAudioPlayer.getDuration(
      stateData.curPlayerData?.player
    );
    renderData.duration = duration;
    
    renderData.progress = Math.min(100, Math.max(0, progress * 100));
    renderData.progressCp = progress;
    renderData.startTime = currentTime;
    renderData.endTime = endTime;

    emits("syncPlayState", renderData.isPlaying);
    emits("syncProgress", renderData.progress, renderData.startTime);
    emits("syncDuration", renderData.duration);
  }
  // console.log("init🍎苹果", stateData.curPlayerData);
};

const handleTouchStart = () => {};

const touchEnd = () => {
  // if (jsBridge.inTiangongApp) {
  //   jsBridge("event.requestGesture", { eventTye: "horizontal", action: "release" })
  // }
};

watch(
  () => renderData.isPlaying,
  (newValue) => {
    emits("syncPlayState", renderData.isPlaying);
  }
);
watch(
  () => renderData.isLoading,
  (newValue) => {
    emits("syncLoadingState", renderData.isLoading);
  }
);
watch(
  () => renderData.progress,
  (newValue) => {
    emits(
      "syncProgress",
      renderData.progress,
      renderData.startTime,
      renderData.currentTime
    );
  }
);

watch(
  () => props.disabled,
  (newValue) => {
    if (props.disabled) {
      console.log("props.disabled", props.disabled);
      globalAudioPlayer.pause(renderData.url, true);
    }
  }
);

watch(
  () => {
    return props.duration;
  },
  () => {
    if (
      renderData.url == globalAudioPlayer.currentUrl &&
      renderData.duration != props.duration
    ) {
      globalAudioPlayer.setDuration(renderData.url, props.duration);
    }
    renderData.duration = props.duration;
    renderData.endTime = mediaUtil.formatTime(props.duration);
  }
);

const rerenderSlide = () => {
  stateData.isChangeProgress = false;
  nextTick().then(() => {
    stateData.isChangeProgress = false;
    renderData.ifRendderSlide = false;
    nextTick(() => {
      stateData.ifRendderSlide = true;
    });
  });
};

watch(
  () => {
    return props.url;
  },
  () => {
    if (props.url != renderData.url) {
      if (stateData.curPlayerData) {
        globalAudioPlayer.releasePlayer(stateData.curPlayerData);
      }
      renderData.url = props.url;
      init();
      rerenderSlide();
    }
  }
);

watch(
  () => {
    return props.loop;
  },
  () => {
    globalAudioPlayer.setLoop(renderData.url, props.loop);
  }
);

watch(
  () => {
    return props.isGenerating;
  },
  (newValue) => {
    renderData.isGenerating = props.isGenerating;
  }
);

watch(
  () => {
    return renderData.isGenerating;
  },
  (newValue) => {
    emits("update:isGenerating", renderData.isGenerating);
    console.log(
      "🍎🍎🍎🍎🍎🍎🍎🍎🍎🍎🍎renderData.isGenerating",
      renderData.isGenerating
    );
  }
);

onMounted(() => {
  renderData.url = props.url;
  renderData.duration = props.duration;
  renderData.endTime = mediaUtil.formatTime(props.duration);
  renderData.loaderSize = getLoadingSize();
  renderData.isGenerating = props.isGenerating;
  init();
  stateData.playerCacheData = {
    uuid: globalAudioPlayer.getUuid(),
    pause() {
      changeOp(false);
    },
    getContainer() {
      return refAudioBox.value;
    },
  };
});

onUnmounted(() => {
  globalAudioPlayer.releasePlayer(stateData.curPlayerData);
});

defineExpose({
  changeOp(isPlay) {
    return new Promise((resolve, reject) => {
      changeOp(isPlay);
      resolve(renderData.isPlaying);
    });
  },
  changeProgress,
  renderData,
  checkAudioStatus: () => {
    renderData.isPlaying =
      globalAudioPlayer.players[renderData.url]?.isPlaying ||
      renderData.isPlaying;
    emits("syncPlayState", renderData.isPlaying);
  },
  triggleEmitsPerContinuousPlaybackTimeCallback() {
    emitsPerContinuousPlaybackTimeCallback();
  },
});
</script>
<style lang="scss">
.audio-player {
  .audio-player-opbox {
  }
  .audio-player {
    align-items: center;
  }
  .audio-player-op {
    height: var(--opSize);
    width: var(--opSize);
    min-width: var(--opSize);
    border-radius: var(--opSize);
    margin: var(--opMargin);
  }
  /*.audio-player-op:hover {
    background-color: var(--opHoverBg);
  }*/
  .apo.pointer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--opSize);
    background: var(--opBgColor);
  }
  i.block {
    width: var(--opIcoSize);
    height: var(--opIcoSize);
  }
  i.icon-loading.block {
    width: calc(var(--opIcoSize) - 6px);
    height: calc(var(--opIcoSize) - 6px);
  }
  /*.apo.pointer:hover{
    background: var(--opBgHover);
  }*/

  .audio-player-op.play {
    background: url(../../../assets/img/play-ico.png);
    background-size: 100% 100%;
  }
  .audio-player-op.stop {
    background: url(../../../assets/img/pause-ico.png);
    background-size: 100% 100%;
  }
  .audio-player-op.ratate-loading {
    height: 20px;
    width: 20px;
  }

  .audio-player-op-switch {
    height: var(--opSwitchSize);
    width: var(--opSwitchSize);
    min-width: var(--opSwitchSize);
    border-radius: var(--opSwitchIcoSize);
    margin-right: 0;
    i {
      width: var(--opSwitchIcoSize);
      height: var(--opSwitchIcoSize);
    }
    &:hover {
      background-color: var(--opSwitchHoverBg);
    }
    &.audio-player-op-switch-prev {
      i {
        background: url(../../../assets/img/switch-prev-ico.png);
        background-size: 100% 100%;
      }
    }
    &.audio-player-op-switch-next {
      margin-right: 8px;
      i {
        background: url(../../../assets/img/switch-next-ico.png);
        background-size: 100% 100%;
      }
    }
  }
  .audio-player-title {
    font-size: var(--titleFontSize);
    font-weight: var(--titleFontWeight);
    color: var(--titleColor);
    line-height: var(--titleFontSize);
    padding: 0 0 5px 0;
    max-width: 100%;
  }
  .audio-player-title-top {
    padding: 0 0 1px 6px;
  }
  .audio-player-time {
    font-family: PingFang SC-Regular;
    font-size: var(--timeFontSize);
    font-weight: var(--timeFontWeight);
    color: var(--timeColor);
    min-width: var(--timeMinWidth);
    letter-spacing: 0.075em;
    i.icon-loading.block {
      width: calc(var(--opIcoSize));
      height: calc(var(--opIcoSize));
      background: url(@/assets/img/loading.png);
      background-size: 100% 100%;
    }
  }
  .audio-player-center {
    margin-left: 4px;
    margin: var(--seekbarMargin);
  }
  .audio-player-slider {
    position: relative;

    .vue-slider {
      height: var(--barHeight) !important;
      .vue-slider-rail {
        height: var(--barHeight);
        width: 100%;
        border-radius: 4px 4px 4px 4px;
        box-sizing: content-box;
        background-color: var(--barColor);
        &:after {
          content: "";
          position: absolute;
          height: 4px;
          width: 4px;
          border-radius: 4px;
          top: -1px;
          left: var(--highPointerLeft);
          background: var(--highPointerColor);
        }
      }

      .vue-slider-process {
        height: var(--barHeight);
        border-radius: 4px 4px 4px 4px;
        background: var(--progressbarColor);
      }
      .vue-slider-dot {
        width: 24px !important;
        height: 24px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        .vue-slider-dot-handle {
          width: var(--progressSliderSize);
          height: var(--progressSliderSize);
          background: var(--progressSliderColor);
        }
        &:hover {
          .vue-slider-dot-handle {
            transform: scale(1.2);
          }
        }
      }
    }
  }
  .render-disabled--true {
    opacity: 0.5;
    .audio-player-op {
      cursor: not-allowed;
    }
    .audio-player-op:hover {
      background-color: transparent;

      .apo.pointer:hover {
        cursor: not-allowed;
        background: transparent;
      }
    }
  }
  .render-disabled-extend--false {
    opacity: 1;
    .audio-player-op {
      cursor: unset;
      opacity: 0.8;
    }
    .audio-player-center {
      cursor: not-allowed;
      opacity: 0.5;
    }
    .audio-player-time {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
</style>
