import { createApp, h } from "vue";
import CreditDialog from "./components/creditsDialog.vue"; // 确保路径正确

let instance = null;

export function openCreditDialog(options = {}) {
  if (instance) {
    instance.dialogVisible = true; // 如果已存在实例，直接打开
    return;
  }

  const container = document.createElement("div");
  document.body.appendChild(container);

  const app = createApp({
    data() {
      return {
        dialogVisible: true, // 控制弹窗显示
      };
    },
    render() {
      return h(CreditDialog, {
        visible: this.dialogVisible,
        ...options,
        "onUpdate:visible": (val) => {
          this.dialogVisible = val;
          if (!val) {
            setTimeout(() => {
              app.unmount();
              document.body.removeChild(container);
              instance = null;
            }, 300);
          }
        },
      });
    },
  });

  instance = app.mount(container);
}
