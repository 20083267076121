import { defineStore } from "pinia";
import { getModelList, queryLoraTaskProcess, addUploads, delUploads, getUploadList, createLora, updateLoraTask, cancelLoraTask, getLoraTaskState, reportSelectModel } from "@/api/api/apiLora.js"
import { firebaseUtils } from "@/utils/analytic/firebaseUtils.js";
import useCreateLayoutStore from "@/store/create/createLayoutStore.js";
import useUserDataStore from "@/store/userDataStore";

const useModelData = defineStore({
    id: "modelDataStore",
    state: () => {
        return {
            model_list: [],
            lora_model_list: [],
            default_model: {},
            current_model: {},
            lora_id: 0,
            lora_state: 0,
            loraUploadList: [],
            uploadList: [], // state = 1 代表未上传成功  2 代表已经上传成功  3 代表上报成功  
            upload_ids: [],
            model_name: '',
            loraRnderData: {
                refreshing: false,
                loadingMore: false,
                isLoading: false,
                loadError: false,
                finished: false,
            },
            generating_progress: 0,
            show_modle: {},
            wait_num: 0,
            // total: 0, // 总数
            show_total: 0,
            choose_model_data: {
                show_choose_model: false,
                is_show: false
            },
            uploadControllers: new Map()
        }
    },
    actions: {
        addTotal() {
            this.show_total++
        },

        showChooseModelPop() {
            this.choose_model_data.is_show = true
        },

        updataChooseModelPop() {
            if (this.choose_model_data.is_show) {
                this.choose_model_data = {
                    show_choose_model: false,
                    is_show: false
                }
                reportSelectModel(
                    {
                        model: this.current_model.model
                    }
                ).then(res => {
                    if (res.code == 200) {
                    }
                })
            }
        },

        getFileNumber() {
            const result = this.uploadList.filter(item => { return [1, 2].includes(item.state) });
            return this.show_total + result.length
        },

        // 获取模型列表
        getAllModelList() {
            // debugger
            return new Promise((resolve) => {
                getModelList(
                    {
                        control_type: 0
                    }
                ).then(res => {
                    if (res.code == 200) {
                        const userDataStore = useUserDataStore();
                        const { official_models, user_models, default_model, current_model } = res.data
                        this.model_list = official_models || []
                        this.lora_model_list = user_models || []
                        this.current_model = current_model || default_model || {}
                        this.lora_model_list.forEach(item => {
                            if (item.model == this.current_model.model) {
                                this.current_model = {
                                    ...this.current_model,
                                    uid: userDataStore?.userInfo?.user?.id
                                }
                            }
                        })
                        this.default_model = default_model || {}
                        if (!current_model) {
                            this.choose_model_data.show_choose_model = true
                            console.error(this.choose_model_data)
                        }
                        resolve(this.current_model)
                    }
                })
            })

        },

        changeCurrentModelAlertState() {
            this.current_model = {
                ...this.current_model,
                need_credit_alert: false
            }
        },

        // 切换当前模型
        updateCurrentModel(model) {
            return new Promise((resolve) => {
                const createLayoutStore = useCreateLayoutStore();
                // 上报
                reportSelectModel(
                    {
                        model: model.model
                    }
                ).then(res => {
                    if (res.code == 200) {
                        firebaseUtils.logCustomEvent("create_model_list_click", {
                            source: this.current_model.model + ',' + model.model,
                            tab_stare: createLayoutStore.createEditMode == 'easy' ? 'basic' : 'advanced'
                        });
                        this.current_model = model
                        // this.changeCurrentModelAlertState()
                        this.choose_model_data = {
                            show_choose_model: false,
                            is_show: false
                        }
                        resolve()
                    }
                })
            })
        },

        // 获取训练模型id
        getLoraId() {
            return new Promise((resolve, reject) => {
                queryLoraTaskProcess({}).then(res => {
                    if (res.code == 200) {
                        this.lora_id = res.data?.id
                        this.lora_state = res.data?.state
                        this.show_modle = res.data?.model
                        this.wait_num = res?.data?.wait_num || 0
                        this.model_name = res.data?.model?.display_name
                        if ([1, 2].includes(res.data?.state)) {
                            this.getModelProgress()
                        }
                        resolve()
                    } else {
                        reject()
                    }
                }).catch(() => {
                    reject()
                })
            })
        },

        // 新增上传文件列表
        addFileList(item) {
            this.uploadList.unshift(item)
        },

        // 更新上传文件列表状态
        updateFileList(uuid, item) {
            let index = this.uploadList.findIndex(
                (itemF) => itemF.uuid === uuid
            );
            if (index > -1) {
                Object.assign(this.uploadList[index], item);
            }
        },

        init() {
            this.loraRnderData.isLoading = true;
            const combinedArray = this.loraUploadList.concat(this.uploadList);
            const ids = [...new Set(combinedArray.map(item => item.id).filter(id => id !== undefined))];
            this.upload_ids = ids
            getUploadList({
                size: 30,
                task_id: this.lora_id,
                ids
            }).then((res) => {
                if (res.code == "200") {
                    this.loraRnderData.isLoading = false;
                    this.loraRnderData.loadError = false;
                    const { data } = res;
                    if (!data.files) {
                        this.loraRnderData.finished = true;
                        this.loraUploadList = []
                    } else {
                        this.loraUploadList = data.files;
                        this.loraRnderData.finished = false;
                    }
                    this.total = data.total || 0;
                    this.show_total = data.total || 0
                } else {
                    this.loraRnderData.isLoading = false;
                    this.loraRnderData.loadError = true;
                }
            }).finally(() => {
            });
        },

        doLoadMore(done) {
            this.loraRnderData.isLoading = true
            const combinedArray = this.loraUploadList.concat(this.uploadList);
            const ids = [...new Set(combinedArray.map(item => item.id).filter(id => id !== undefined))];
            this.upload_ids = ids
            getUploadList({
                size: 30,
                task_id: this.lora_id,
                ids
            })
                .then((res) => {
                    if (res.code == 200) {
                        this.loraRnderData.loadError = false;
                        const { data } = res;
                        if (!data.files) {
                            done && done(this.loraRnderData, true);
                        } else {
                            this.loraUploadList = [...this.loraUploadList, ...data.files];
                            done && done(this.loraRnderData, false);
                        }
                    } else {
                        this.loraRnderData.loadError = true;
                    }
                })
                .finally(() => { });
        },

        // 上传训练素材
        reportUpload(uuid) {
            return new Promise((resolve, reject) => {
                const item = this.uploadList.find(item => item.uuid == uuid)
                if (item) {
                    const { url, name, duration } = item
                    const controller = new AbortController();
                    this.uploadControllers.set(uuid, controller); // 存储 controller
                    addUploads(
                        {
                            task_id: this.lora_id,
                            url,
                            name,
                            duration
                        }, { controller }).then(res => {
                            if (res.code == 200) {
                                resolve(res)
                            } else if (res.code == 6344) {
                                resolve({
                                    code: 6344
                                })
                            }
                            else {
                                resolve({
                                    code: 500
                                })
                            }
                        }).catch(() => {
                            resolve({
                                code: 500
                            })
                        }).finally(() => {
                            this.uploadControllers.delete(uuid)
                        })
                }

            })
        },

        // 取消单个请求
        cancelUpload(uuid) {
            const controller = this.uploadControllers.get(uuid);
            if (controller) {
                controller.abort(); // 取消请求
                this.uploadControllers.delete(uuid); // 从 Map 中移除
                console.log(`请求 ${uuid} 已取消`);
            } else {
                console.log(`未找到请求 ${uuid}`);
            }
        },

        // 删除上传文件
        deleteUploadItem(uuid, callBack) {
            let index = this.uploadList.findIndex(
                (itemF) => itemF.uuid === uuid
            );
            if (index > -1) {
                const id = this.uploadList[index]?.id
                this.upload_ids = this.upload_ids.filter(cid => cid !== id);
                if (id) {
                    delUploads({
                        id,
                        task_id: this.lora_id
                    }).then(res => {
                        if (res.code == 200) {
                            this.uploadList.splice(index, 1)
                        }
                    })
                    this.show_total--
                    if (this.show_total > 30 && this.uploadList.length + this.loraUploadList.length < 30 ) {
                        callBack()
                    }
                }
                else {
                    this.cancelUpload(uuid)
                    this.uploadList.splice(index, 1)
                }
            }
        },

        deleteItem(uuid) {
            let index = this.uploadList.findIndex(
                (itemF) => itemF.uuid === uuid
            );
            if (index > -1) {
                this.uploadList.splice(index, 1)
            }
        },

        // 重试
        retryUploadItem(uuid) {
            return new Promise((resolve) => {
                let item = this.uploadList.find(
                    (itemF) => itemF.uuid === uuid
                );
                resolve(item)
            })

        },

        // 删除已保存文件列表
        deleteUploadSavedItem(id, callBack) {
            delUploads({
                id,
                task_id: this.lora_id
            }).then(res => {
                if (res.code == 200) {
                    let index = this.loraUploadList.findIndex(
                        (itemF) => itemF.id === id
                    );
                    if (index > -1) {
                        this.upload_ids = this.upload_ids.filter(cid => cid !== id);
                        this.loraUploadList.splice(index, 1)
                        this.show_total--
                        if (this.show_total > 30 && this.uploadList.length + this.loraUploadList.length < 30 ) {
                            callBack()
                        }
                    }
                }
            })
        },

        // 创建模型
        createModel() {
            const createLayoutStore = useCreateLayoutStore();
            return new Promise((resolve, reject) => {
                createLora(
                    {
                        id: this.lora_id,
                        model: this.model_name,
                        source: createLayoutStore.createEditMode == 'easy' ? 'basic' : 'advanced'
                    }
                ).then(res => {
                    if (res.code == 200) {
                        this.lora_state = res.data?.state
                        this.wait_num = res.data?.wait_num || 0
                        this.show_modle = res.data?.model
                        // 开始轮询
                        this.getModelProgress()
                        resolve()
                    } else {
                        reject(res)
                    }
                }).catch(() => {
                    reject()
                })
            })
        },

        // 保存模型name
        saveModelName() {
            updateLoraTask({
                id: this.lora_id,
                model_name: this.model_name
            }).then(res => {

            })
        },

        getModelProgress() {
            const interval = 20000; // 20 seconds
            const allowedStates = [3, 4, 6, 7];
            const intervalId = setInterval(() => {
                getLoraTaskState({
                    id: this.lora_id
                }).then(res => {
                    if (res.code == 200) {
                        this.lora_state = res?.data?.state
                        if (allowedStates.includes(res?.data?.state)) {
                            clearInterval(intervalId);
                            if (res?.data?.state == 3) {
                                this.updateLoraList(this.show_modle)
                            }
                        } else if (res?.data?.state == 1) {
                            this.wait_num = res?.data?.wait_num || 0
                        } else if (res?.data?.state == 2) {
                            this.generating_progress = res?.data?.process || 0
                        }
                    }
                })
            }, interval);
        },

        // 更新lora列表
        updateLoraList(item) {
            let index = this.lora_model_list.findIndex(
                (itemF) => itemF.model === item.model
            );
            if (index == -1) {
                this.lora_model_list.push(item)
            }
        },

        // 取消失败任务
        cancelFailLora() {
            return new Promise((resolve) => {
                cancelLoraTask(
                    {
                        id: this.lora_id
                    }
                ).then(res => {
                    if (res.code == 200) {
                        // this.loraUploadList = [],
                        this.uploadList = []
                        this.lora_state = 7
                        this.getLoraId()
                        this.init()
                        resolve()
                    }
                })
            })

        },

        // 重置
        resetModel() {
            this.lora_id = 0
            this.lora_state = 0
            this.loraUploadList = []
            this.uploadList = []
            this.upload_ids = []
        }
    }
})

export default useModelData;