<template>
  <div
    class="audio-wave-player"
    :class="{
      [`audio-wave-player--${createRenderType}`]: true,
    }"
    :style="renderData.renderStyle"
  >
    <AudioPlayer
      ref="refPlayer"
      :url="props.onlyRenderMode ? '' : props.url"
      :duration="renderData.duration"
      :autoToStartIfNotCurrent="props.autoToStartIfNotCurrent"
      :canSeekNotCurrent="props.canSeekNotCurrent"
      @syncDuration="syncDuration"
      @syncPlayState="syncPlayState"
      @syncLoadingState="syncLoadingState"
      @syncProgress="syncProgress"
    >
      <div class="audio-wave-player-main flex">
        <div class="audio-wave-player-op">
          <div class="flex-center height-all">
            <LoaderProgressCircle
              :size="createRenderType == 'mobile' ? 40 : 48"
              default-color="rgba(255,255,255,1)"
              progress-color="#94ADFF"
              :progress="0"
            >
              <div
                class="audio-item-op flex-center pointer"
                @click.stop="doTogglePlay"
              >
                <i
                  class="block"
                  :class="{
                    'isplay-true': !renderData.isplay,
                    'isplay-false': renderData.isplay,
                  }"
                ></i>
              </div>
            </LoaderProgressCircle>
          </div>
        </div>
        <div class="audio-wave-player-wave-box">
          <div class="audio-wave-player-wave">
            <AudioWavForm
              :url="renderData.url"
              :style="{
                '--progressColor': renderData.renderStyle['--progressColor'],
                '--progressHoverColor':
                  renderData.renderStyle['--progressHoverColor'],
                '--progressTrasform':
                  renderData.renderStyle['--progressTrasform'],
                '--progressTimeTop':
                  renderData.renderStyle['--progressTimeTop'],
              }"
              :isPlaying="renderData.isplay"
              :waveformData="props.waveformData"
              :showChangeProgress="true"
              :progress="renderData.progress / 100"
              @changeProgress="changeProgress"
              :hideChangeProgress="false"
              :dragSeek="props.dragSeek"
              :config="props.waveRenderConfig"
              :formatTimeByProgress="props.formatTimeByProgress"
            ></AudioWavForm>
          </div>
        </div>
      </div>
    </AudioPlayer>
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  computed,
  readonly,
  reactive,
  watch,
  defineExpose,
  onMounted,
  onUpdated,
  onUnmounted,
} from "vue";

import { useRouter, useRoute } from "vue-router";

import AudioWavForm from "./AudioWavForm.vue";
import AudioPlayer from "./AudioPlayer.vue";

import { storeToRefs } from "pinia";
import useCreateLayoutStore from "@/store/create/createLayoutStore.js";
const createLayoutStore = useCreateLayoutStore();
const { createRenderType } = storeToRefs(createLayoutStore);

const Router = useRouter();
const route = useRoute();

const props = defineProps({
  waveRenderConfig: {
    type: Object,
    default: () => {
      return {
        spacingSize: 3,
        wavebarWidth: 1,
        startColor: "#72FFEC",
        endColor: "#72FFEC",
        defaultColor: "rgba(255, 255, 255, 1)",
        progressColor: "#72FFEC",
      };
    },
  },
  renderStyle: {
    type: Object,
    default: null,
  },
  url: {
    type: String,
    default: "",
  },
  duration: {
    type: Number,
    default: 0,
  },
  waveformData: {
    type: Array,
    default: () => [],
  },
  onlyRenderMode: {
    // 此模式url 播放无效仅仅用来渲染
    type: Boolean,
    default: false,
  },
  progress: {
    type: Number,
    default: 0,
  },
  isplay: {
    type: Boolean,
    default: false,
  },
  dragSeek: {
    type: Boolean,
    default: false,
  },
  formatTimeByProgress: {
    type: Function,
    default: null,
  },
  autoToStartIfNotCurrent: {
    type: Boolean,
    default: true,
  },
  canSeekNotCurrent: {
    type: Boolean,
    default: false,
  },
});

const renderDefaultStyleMap = {
  "--height": "120px",
  "--opAreaBackground": "rgba(148, 173, 255, 0.3)",
  "--waveBackground": "rgba(148, 173, 255, 0.6)",
  "--waveCanvasHeight": "50px",
  "--waveBoxPadding": "35px 0 35px 0",

  "--opProgressCircleColor": "rgba(255,255,255,1)",
  "--opProgressCircleColor": "#94ADFF",
  "--opIcoSize": "20px",
  "--opAreaWidth": "106px",
  "--opAreaBorderRight": "2px solid rgba(148, 173, 255, 0.2)",

  "--progressColor": "rgba(255, 255, 255, 1)",
  "--progressHoverColor": "rgba(255, 255, 255, 0.5)",
  "--progressTrasform": "scale(1, 2.4)",
};

const emits = defineEmits([
  "syncDuration",
  "syncPlayState",
  "syncLoadingState",
  "syncProgress",
  "togglePlay",
  "changeProgress",
]);

const refPlayer = ref(null);

const renderData = reactive({
  renderStyle: Object.assign(
    {},
    renderDefaultStyleMap,
    props.renderStyle ?? {}
  ),
  duration: props.duration,
  isplay: false,
  buffer: false,
  startTime: 0,
  progress: 0,
});

const syncDuration = (duration) => {
  renderData.duration = duration;
  emits("syncDuration", duration);
};

const syncPlayState = (playState) => {
  renderData.isplay = playState;
  emits("syncPlayState", playState);
};
const syncLoadingState = (loadingState) => {
  renderData.buffer = loadingState;
  emits("syncLoadingState", loadingState);
};
const syncProgress = (progress, startTime, currentTime) => {
  renderData.progress = progress;
  renderData.startTime = startTime;
  emits("syncProgress", progress, startTime, currentTime);
};

const doTogglePlay = () => {
  if (props.onlyRenderMode) {
    emits("togglePlay", renderData.isplay);
  } else {
    refPlayer.value?.changeOp();
  }
};

const changeProgress = (progress) => {
  if (props.onlyRenderMode) {
    emits("changeProgress", progress * 100);
  } else {
    refPlayer.value?.changeProgress(progress * 100);
  }
};

onMounted(() => {
  renderData.duration = (props.item?.duration_milliseconds || 0) / 1000;
});

watch(
  () => {
    return props.duration;
  },
  () => {
    renderData.duration = props.duration;
  }
);

watch(
  () => {
    return props.isplay;
  },
  () => {
    renderData.isplay = props.isplay;
  }
);

watch(
  () => {
    return props.progress;
  },
  () => {
    renderData.progress = props.progress;
  }
);

watch(
  () => {
    return props.renderStyle;
  },
  () => {
    renderData.renderStyle = Object.assign(
      {},
      renderDefaultStyleMap,
      props.renderStyle ?? {}
    );
  }
);

defineExpose({
  changeProgress,
  changeOp: (isPlay) => {
    if (props.onlyRenderMode) {
      emits("doTogglePlay", isPlay ?? renderData.isplay);
    } else {
      refPlayer.value?.changeOp(isPlay ?? renderData.isplay);
    }
  },
});
</script>
<style lang="scss">
.audio-wave-player {
  .audio-wave-player-main {
    align-items: center;
  }
  .audio-wave-player-op {
    height: var(--height);
    width: var(--opAreaWidth);
    background: var(--opAreaBackground);
    border-right: var(--opAreaBorderRight);
    .loader-progress:hover {
      opacity: 0.75;
    }
    .audio-item-op {
      height: 100%;
      width: 100%;
    }
    i {
      height: var(--opIcoSize);
      width: var(--opIcoSize);
    }
    .isplay-true {
      background: url("@/assets/img/play-light-ico.png");
      background-size: 100% 100%;
    }
    .isplay-false {
      background: url("@/assets/img/pause-light-ico.png");
      background-size: 100% 100%;
    }
  }
  .audio-wave-player-wave-box {
    flex: 1;
    width: 0;
    height: var(--height);
    background: var(--waveBackground);
    padding: var(--waveBoxPadding);
    .audio-wave-player-wave {
      height: var(--waveCanvasHeight);
    }
  }
}
.audio-wave-player--mobile {
  .audio-wave-player-op {
    i {
      height: 16px;
      width: 16px;
    }
  }
}
</style>
