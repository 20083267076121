/*
 * @author: lihaifa
 * @LastEditTime: 2024-03-15 18:00:48
 * @Description:
 * @FilePath: src/components/media/utils/clipUtil.js
 */
import mediaUtil from "./utils.js";
import tools from "@/utils/tools.js";
import Crunker from "./crunker.js";
export const sampleRate = 44100;
export const fileFormat = "audio/wav";
import ffmpegUtil from "@/components/media/utils/ffmpegUtil.js";

// const config={
//   sampleRate,
//   spacingSize: 2,
//   wavebarWidth: 4,
//   clipDuration: 10,
// }

const AudioFileTypeToExtensionMap = {
  'audio/aac': '.aac',
  'audio/flac': '.flac',
  'audio/mid': '.mid',
  'audio/midi': '.midi',
  'audio/mp3': '.mp3', // 推荐
  'audio/mpeg': '.mp3', // 推荐
  'audio/ogg': '.ogg',
  'audio/wav': '.wav', // 推荐
  'audio/webm': '.webm',
  // 如果需要添加其他音频格式，请根据实际情况继续补充...
};
export const clipdDecodeData = async (originalBuffer, from, to) => {
  // from to 秒
  const sampleRate = originalBuffer.sampleRate;
  const fromc = from >= 0 ? mediaUtil.trimTo(from, 3) : 0;
  const duration =
    to > 0 ? mediaUtil.trimTo(to - fromc, 3) : originalBuffer.duration;
  const newLen = ((duration / 1) * sampleRate) >> 0;
  const newOffset = ((fromc / 1) * sampleRate) >> 0;
  const audioCtx = new (window.AudioContext || window.webkitAudioContext)({
    sampleRate: sampleRate,
  });
  const centerSegment = audioCtx.createBuffer(
    originalBuffer.numberOfChannels,
    newLen,
    sampleRate
  );
  const activeChannels = originalBuffer.numberOfChannels;
  for (let i = 0; i < activeChannels; ++i) {
    centerSegment
      .getChannelData(i)
      .set(
        originalBuffer.getChannelData(i).slice(newOffset, newLen + newOffset)
      );
  }
  return centerSegment;
};

export const getDecodeDataByUrl = async (url) => {
  const response = await fetch(url);
  const resblob = await response.blob();
  const arrayBuffer = await resblob.arrayBuffer();
  const audioCtx = new (window.AudioContext || window.webkitAudioContext)({
    sampleRate: sampleRate,
  });
  const decode = audioCtx.decodeAudioData(arrayBuffer);
  decode.finally(() => audioCtx.close());
  return await decode;
};


// export const exportDecodeData_Old = async (decodeData, type = 'audio/wav') => {
//   // 写入字符串到DataView
//   const writeString = (view, offset, string) => {
//     for (let i = 0; i < string.length; i++) {
//       view.setUint8(offset + i, string.charCodeAt(i));
//     }
//   };

//   // 将浮点 PCM 转换为 16 位 PCM
//   const floatTo16BitPCM = (output, decodeData, input) => {
//     for (var r = 0; r < decodeData.length; r++, input += 2) {
//       var a = Math.max(-1, Math.min(1, decodeData[r]));
//       output.setInt16(input, a < 0 ? 32768 * a : 32767 * a, !0);
//     }
//     return output;
//   }

//   // 交错声道数据
//   const interleave = (decodeData) => {
//     for (
//       var t = Array.from(
//         { length: decodeData.numberOfChannels },
//         function (e, t) {
//           return t;
//         }
//       ),
//       n = t.reduce(function (t, n) {
//         return t + decodeData.getChannelData(n).length;
//       }, 0),
//       r = new Float32Array(n),
//       a = 0,
//       o = 0;
//       a < n;

//     )
//       t.forEach(function (t) {
//         r[a++] = decodeData.getChannelData(t)[o];
//       }),
//         o++;
//     return r;
//   }

//   const e = interleave(decodeData);

//   const n = decodeData.sampleRate;
//   const t = decodeData.numberOfChannels;

//   // 创建 WAV 文件头部
//   var r = 2 * t;
//   let a = 2 * e.length;
//   let o = 36 + a;
//   let i = new ArrayBuffer(8 + o);
//   let u = new DataView(i);

//   writeString(u, 0, 'RIFF');
//   u.setUint32(4, o, true);
//   writeString(u, 8, 'WAVE');
//   writeString(u, 12, 'fmt ');
//   u.setUint32(16, 16, true);
//   u.setUint16(20, 1, true);
//   u.setUint16(22, t, true);
//   u.setUint32(24, n, true);
//   u.setUint32(28, n * r, true);
//   u.setUint16(32, r, true);
//   u.setUint16(34, 16, true);
//   writeString(u, 36, 'data');
//   u.setUint32(40, a, true);


//   // 写入 PCM 数据
//   const blob = new Blob([floatTo16BitPCM(u, e, 44)], { type });
//   const url = URL.createObjectURL(blob);
//   const fileName = `output${AudioFileTypeToExtensionMap[type]??'.wav'}`;
//   const file = new File([blob], fileName, { type });
//   return {
//     file: file,
//     blob: blob,
//     url: url,
//     duration: decodeData.duration
//   };
// };
export const exportDecodeData = async (decodeData, type = 'audio/wav') => {
  // 写入字符串到 DataView
  const writeString = (view, offset, string) => {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  };

  // 将浮点 PCM 转换为 16 位 PCM
  const floatTo16BitPCM = (view, offset, float32Array) => {
    for (let i = 0; i < float32Array.length; i++, offset += 2) {
      const sample = Math.max(-1, Math.min(1, float32Array[i])); // 限制范围 [-1, 1]
      view.setInt16(offset, sample < 0 ? sample * 0x8000 : sample * 0x7FFF, true);
    }
  };
  // 交错声道数据
  const interleave = (decodeData) => {
    const { numberOfChannels, length } = decodeData;
    const result = new Float32Array(length * numberOfChannels);

    for (let channel = 0; channel < numberOfChannels; channel++) {
      const channelData = decodeData.getChannelData(channel);
      for (let i = 0; i < length; i++) {
        result[i * numberOfChannels + channel] = channelData[i];
      }
    }
    return result;
  };

  const interleavedData = interleave(decodeData);
  const sampleRate = decodeData.sampleRate;
  const numChannels = decodeData.numberOfChannels;

  // 计算 WAV 文件大小
  const byteRate = sampleRate * numChannels * 2;
  const dataSize = interleavedData.length * 2;
  const chunkSize = 36 + dataSize;
  const buffer = new ArrayBuffer(44 + dataSize);
  const view = new DataView(buffer);

  // 写入 WAV 头部
  writeString(view, 0, 'RIFF');
  view.setUint32(4, chunkSize, true);
  writeString(view, 8, 'WAVE');
  writeString(view, 12, 'fmt ');
  view.setUint32(16, 16, true);
  view.setUint16(20, 1, true);
  view.setUint16(22, numChannels, true);
  view.setUint32(24, sampleRate, true);
  view.setUint32(28, byteRate, true);
  view.setUint16(32, numChannels * 2, true);
  view.setUint16(34, 16, true);
  writeString(view, 36, 'data');
  view.setUint32(40, dataSize, true);

  // 写入 PCM 数据
  floatTo16BitPCM(view, 44, interleavedData);

  // 创建 Blob 和 File
  const blob = new Blob([buffer], { type });
  const file = new File([blob], `output${AudioFileTypeToExtensionMap[type] ?? '.wav'}`, { type });
  const url = URL.createObjectURL(blob);

  return {
    file,
    blob,
    url,
    duration: decodeData.duration,
  };
};

export const mergeAudio = async (audioBuffers) => {
  const maxNumberOfChannels = Math.max(...(audioBuffers.map((audioBuffer) => audioBuffer.numberOfChannels)));
  const maxDuration = Math.max(...(audioBuffers.map((audioBuffer) => audioBuffer.duration)));
  const sampleRate = Math.max(...(audioBuffers.map((audioBuffer) => audioBuffer.sampleRate)));
  const AudioContext = new (window.AudioContext || window.webkitAudioContext || window.mozAudioContext);

  // 创建目标缓冲区
  const targetBuffer = new AudioContext().createBuffer(
    maxNumberOfChannels,
    sampleRate * maxDuration,
    sampleRate
  );
  // 遍历每个输入音频缓冲区
  audioBuffers.forEach((audioBuffer) => {
    for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
      const targetData = targetBuffer.getChannelData(channel); // 目标通道数据
      const sourceData = audioBuffer.getChannelData(channel); // 输入通道数据

      // 叠加数据
      for (let i = 0; i < sourceData.length; i++) {
        targetData[i] += sourceData[i];
      }
    }
  });

  return targetBuffer;
}

export const concatAudio = async (audioBuffers) => {
  const maxNumberOfChannels = Math.max(...(audioBuffers.map((audioBuffer) => audioBuffer.numberOfChannels)));
  const totalLength = audioBuffers.map((audioBuffer) => audioBuffer.length).reduce((a, b) => a + b, 0);
  const sampleRate = Math.max(...(audioBuffers.map((audioBuffer) => audioBuffer.sampleRate)));
  const AudioContext = new (window.AudioContext || window.webkitAudioContext || window.mozAudioContext);

  // 创建目标缓冲区
  const targetBuffer = new AudioContext().createBuffer(
    maxNumberOfChannels,
    totalLength,
    sampleRate
  );

  // 遍历每个输入音频缓冲区
  let offset = 0;
  audioBuffers.forEach((audioBuffer) => {
    for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
      const targetData = targetBuffer.getChannelData(channel); // 目标通道数据
      const sourceData = audioBuffer.getChannelData(channel); // 输入通道数据
      // 复制数据
      targetData.set(sourceData, offset); // 将源数据复制到目标缓冲区的适当位置
    }
  })

  return targetBuffer;
}


export const padAudio = async (audioBuffer, padStart = 0, seconds = 0) => {
  // 参数检查
  if (padStart < 0) {
    throw new Error('Parameter "padStart" in padAudio must be positive');
  }
  if (seconds < 0) {
    throw new Error('Parameter "seconds" in padAudio must be positive');
  }
  if (seconds === 0) {
    return audioBuffer; // 如果不需要填充，直接返回原音频缓冲区
  }

  // 创建 AudioContext
  const AudioContext = new (window.AudioContext || window.webkitAudioContext || window.mozAudioContext);

  // 计算目标缓冲区的总长度
  const totalLength = Math.ceil(audioBuffer.length + seconds * audioBuffer.sampleRate);

  // 创建目标缓冲区
  const targetBuffer = AudioContext.createBuffer(
    audioBuffer.numberOfChannels,
    totalLength,
    audioBuffer.sampleRate
  );

  // 遍历每个通道，将数据复制到目标缓冲区
  for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
    const targetData = targetBuffer.getChannelData(channel); // 目标通道数据
    const sourceData = audioBuffer.getChannelData(channel); // 输入通道数据

    // 计算填充起始点
    const padStartSamples = Math.ceil(padStart * audioBuffer.sampleRate);

    // 将原始音频数据复制到目标缓冲区的指定位置
    targetData.set(sourceData.subarray(0, padStartSamples), 0); // 填充开始部分
    targetData.set(sourceData.subarray(padStartSamples), padStartSamples + Math.ceil(seconds * audioBuffer.sampleRate)); // 填充剩余部分
  }

  return targetBuffer;
};

export const getCrunkerExportData = async (
  url,
  from,
  to,
  type = fileFormat
) => {
  const centerSegment = await clipdDecodeData(
    await getDecodedata(url),
    from,
    to
  );
  const crunker = new Crunker({ sampleRate: sampleRate });
  const crunkerExport = await crunker.export(centerSegment, type);
  crunkerExport.duration = centerSegment.duration;
  return crunkerExport;
};

export const decodeDataToUrl = async (decodeData, type = fileFormat) => {
  const crunker = new Crunker({ sampleRate: sampleRate });
  const crunkerExport = await crunker.export(decodeData, type);
  crunkerExport.duration = decodeData.duration;
  // const crunkerExport = await exportDecodeData(decodeData, type);
  return crunkerExport;
};

export const blobUrlToFile = async (blobUrl, fileName) => {
  // 使用 Fetch API 下载 Blob 数据
  const response = await fetch(blobUrl);
  const blob = await response.blob();

  // 创建 File 对象
  const file = new File([blob], fileName, { type: blob.type });

  return file;
};

export const blobToFile = async (blob, fileName) => {
  return new File([blob], fileName, { type: blob.type });
};

export const downloadAndClipUrl = async (url, from, to) => {
  // from to 秒
  const crunkerExport = await getCrunkerExportData(url, from, to);
  tools.downLoadAtag({
    blob: crunkerExport.blob,
    fileName: "download" + url.match(/.[^.]+$/)[0],
  });
};

export const getDecodeDataByFile = (file) => {
  // 获取文件音频时长
  return new Promise((resolve, reject) => {
    try {
      if (file) {
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        let reader = new FileReader();
        reader.onload = function (e) {
          const arrayBuffer = e.target.result;
          try {
            audioContext.decodeAudioData(
              arrayBuffer,
              (decodeData) => {
                resolve(decodeData);
              },
              (error) => {
                reject(error);
              }
            );
          } catch (e) {
            reject(e);
          }
        };
        reader.readAsArrayBuffer(file);
      } else {
        resolve(0);
      }
    } catch (e) {
      reject(e);
    }
  });
};

export const getWavebarData = (
  decodedata,
  points = 200,
  averageCount = 100,
  endClipTime = 0
) => {
  const calcNumberOfChannels = 1;
  const numberOfChannels = decodedata.numberOfChannels;
  let channelDatas = new Array(numberOfChannels).fill(0).map((_, index) => {
    let channelData = decodedata.getChannelData(index);
    return channelData.slice(
      0,
      Math.floor(
        (channelData.length * Math.max(0, decodedata.duration - endClipTime)) /
        decodedata.duration
      )
    );
  });
  let channelDatasChunk = channelDatas
    .slice(0, calcNumberOfChannels)
    .map((item) => splitArray(item, points));
  return channelDatasChunk[0].map((chuck, index) => {
    const step = Math.floor((chuck.length ?? 0) / averageCount);
    let sum = 0;
    for (let i = 0; i < chuck.length; i += step) {
      for (let j = 0; j < calcNumberOfChannels; j++) {
        sum += Math.abs(channelDatasChunk[j][index][i] ?? 0);
      }
    }
    const average = sum / (chuck.length / step);
    return average;
  });
};

export const getWaveDataByWaveFormData = (waveFormData, count) => {
  const waveSplitData = splitArray(waveFormData, count);
  return waveSplitData.map((item, index) => {
    const sum = item.reduce((pre, cur) => pre + cur, 0);
    return sum / item.length;
  });
};

export const fixedPaintHarfHeightWaveBarData = (data, HalfHeight) => {
  const max = Math.max(...data);
  if (max == 0) {
    return data.map((item) => {
      return 1;
    });
  }
  const gainRatio = HalfHeight / max;
  return data.map((item) => {
    let gainHeight = Math.floor(item * gainRatio);
    return gainHeight <= 0 ? 1 : gainHeight;
  });
};

export const splitArray = (arr, count) => {
  if (arr.length === 0 || count <= 0) return [];
  const size = Math.floor(arr.length / count); // 每组的基础大小
  const sizeMod = arr.length % count; // 多出来的元素数量
  const result = new Array(count)
    .fill()
    .map((_, i) =>
      arr.slice(
        i * size + Math.min(i, sizeMod),
        (i + 1) * size + Math.min(i + 1, sizeMod)
      )
    );
  return result;
};

export const getFileDataUrl = (file) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = function (event) {
        const dataURL = event.target.result;
        resolve(dataURL);
        console.log(dataURL);
      };
      reader.onerror = function (event) {
        console.log("getFileDataUrl_event.target.error", event.target.error);
        reject(event.target.error);
      };
      reader.readAsDataURL(file);
    } catch (e) {
      console.error("getFileDataUrl", e);
      reject(e);
    }
  });
};
export const downloadFile = async (url, filename) => {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function (e) {
      if (this.status === 200) {
        var blob = new Blob([this.response], { type: "text/plain" });
        const resfile = new File([blob], filename, { type: blob.type });
        resolve(resfile);
      } else {
        reject(this.statusText);
      }
    };
    xhr.send();
  });
};
export const getFileDecodeData = async (file) => {
  return new Promise(async (resolve, reject) => {
    let decodeData = null;
    let blobUrl = null;
    try {
      decodeData = await getDecodeDataByFile(file);
      blobUrl = (window.URL || window.webkitURL).createObjectURL(file);
      resolve({
        blobUrl,
        decodeData,
      });
    } catch (e) {
      try {
        file = await ffmpegUtil.transFileToMp3(file);
        blobUrl = outputfile.blobUrl;
        decodeData = await getDecodeDataByFile(file);
        resolve({
          blobUrl,
          decodeData,
        });
      } catch (e) {
        reject(e);
      }
    }
  });
};
export const getWaveDataByUrl = (url, count, endClipTime) => {
  return new Promise((resolve, reject) => {
    downloadFile(url)
      .then((file) => {
        getFileDecodeData(file)
          .then(({ decodeData, blobUrl }) => {
            let wavebarData = getWavebarData(
              decodeData,
              count,
              100,
              endClipTime
            );
            resolve(wavebarData);
          })
          .catch((e) => {
            reject(e);
          });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const isMP3ByMimeType = (mimeType) => {
  // 检查 MIME 类型是否为 audio/mpeg
  return mimeType === 'audio/mpeg';
}

const isMP3ByExtension = (filename) => {
  // 将文件名转换为小写，并检查是否以 .mp3 结尾
  return filename.toLowerCase().endsWith('.mp3');
}

export default {
  sampleRate,
  // config,
  clipdDecodeData,
  getDecodeDataByUrl,
  decodeDataToUrl,
  blobUrlToFile,
  blobToFile,
  getDecodeDataByFile,
  exportDecodeData,
  getCrunkerExportData,
  downloadAndClipUrl,
  getWavebarData,
  getWaveDataByWaveFormData,
  fixedPaintHarfHeightWaveBarData,
  getFileDataUrl,
  downloadFile,
  getFileDecodeData,
  getWaveDataByUrl,
  isMP3ByMimeType,
  isMP3ByExtension,
};
// 调用例子
// downloadAndClipUrl('https://musicaigc-na-1256122840.cos.na-ashburn.myqcloud.com/naprod/svcs/sms/ai-sm-1-Frozen.mp3',0,20)
