<template>
  <div
    class="scroll-load-box height-all"
    isScroll="true"
    ref="refScrollBox"
    :class="{
      'scroll-load-box-show-empty': renderFinishedEmpty,
    }"
  >
    <MescrollVue
      v-if="renderData.isRender"
      ref="refMescroll"
      class="mescroll_feed_list"
      @init="mescrollInit"
      :down="renderData.mescrollConfig.down"
      :up="renderData.mescrollConfig.up"
    >
      <QueryEmptyRetry
        v-if="renderFinishedEmpty && !$slots.customContent"
        type="emptydata"
        :info="showInfo"
        :hideRetry="true"
      ></QueryEmptyRetry>
      <slot v-if="$slots.customContent" name="customContent"></slot>
      <div
        v-if="!$slots.customContent && !renderFinishedEmpty"
        class="pull-to-load-main-list"
      >
        <template
          v-for="(item, index) in renderData.datas"
          :key="getRenderKey(item, props.itemIdField)"
        >
          <slot
            :item="{ ...item, key: getRenderKey(item, props.itemIdField) }"
            :index="index"
          ></slot>
        </template>
      </div>
    </MescrollVue>
  </div>
</template>
<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  computed,
  onMounted,
  readonly,
  reactive,
  watch,
  defineExpose,
  nextTick,
  useSlots,
} from "vue";

// 使用 useSlots 函数获取插槽
const $slots = useSlots();

// import MeScroll from 'mescroll.js/mescroll.min.js'
import MescrollVue from "./mescroll/Mescroll.vue";
import QueryEmptyRetry from "./queryEmptyRetry.vue";
import { getDataByFields, getUuid } from "@/utils/tools.js";
import {
  $$language,
  $$t,
  $$registLanguageChangeListener,
  $$removeLanguageChangeListener,
} from "@/i18n/i18n.js";

const getRenderKey = (item, itemIdField) => {
  return getDataByFields(item, itemIdField) ?? getUuid();
};
const props = defineProps({
  initLoad: {
    type: Boolean,
    default: false,
  },
  itemIdField: {
    type: String,
    default: "id",
  },
  disabledRefresh: {
    type: Boolean,
    default: false,
  },
  disabledLoadMore: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Array,
    default: [],
  },
  refreshing: {
    type: Boolean,
    default: false,
  },
  finished: {
    type: Boolean,
    default: false,
  },
  loadingMore: {
    type: Boolean,
    default: false,
  },
  datas: {
    type: Array,
    default: () => [],
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  showInfo: {
    type: Object,
    default: () => ({
      networkerror: $$t("common.network_error"),
      loadfailure: $$t("common.load_failure"),
      contentviolation: $$t("common.content_violation"),
      emptydata: $$t("common.empty_data"),
    }),
  },
});
const emits = defineEmits([
  "refresh",
  "loadMore",
  "update:refreshing",
  "update:loadingMore",
  "update:isLoading",
  "update:finished",
  "update:disabledRefresh",
  "update:disabledLoadMore",
  "onScroll",
]);
const refMescroll = ref(null);
const refScrollBox = ref(null);
const refFeedlistBox = ref(null);
const getMescrollConfig = () => {
  const pullTextConfig = {
    refreshText: $$t("common.refresh_text"), // "pull to refresh",
    refreshingText: $$t("common.refreshing_text"), // "Refreshing",
    loadMoreText: $$t("common.load_more_text"), //"Load more",
    loadMoreNoDataText: $$t("common.load_more_end_text"), //"No more data",
    loadingText: $$t("common.loading_text"), // "Loading...",
    releaseUpdateText: $$t("common.release_update_text"), // "Release update"
  };
  return {
    //如果您的下拉刷新是重置列表数据,那么down完全可以不用配置,具体用法参考第一个基础案例
    down: {
      use: !props.disabledRefresh,
      auto: false,
      offset: 80,
      callback: (mescroll) => {
        refresh(
          (pageDataLength, hasNext) => {
            mescroll.endSuccess(pageDataLength ?? 20, hasNext);
          },
          () => {
            mescroll.endErr();
          }
        );
      },
      textInOffset: pullTextConfig.refreshText, // 下拉的距离在offset范围内的提示文本
      textOutOffset: pullTextConfig.releaseUpdateText, // "Release update", // 下拉的距离大于offset范围的提示文本
      textLoading: pullTextConfig.loadingText, // Loading...
      htmlContent: `
          <div
            class="scroll-load-box-refreshing scroll-load-box-refreshing-top flex-center"
          >
          <div class="slb-refreshing rotate-loading">
            </div>
            <div class="slb-text">
              ${pullTextConfig.refreshText}
            </div>
            
          </div>
        `,
      textRefreshing: `
          <div
            class="scroll-load-box-refreshing scroll-load-box-refreshing-top flex-center"
          >
          <div class="slb-refreshing rotate-loading">
            </div>
            <div class="slb-text">
              ${pullTextConfig.refreshingText}
            </div>
            
          </div>
        `,
    },
    up: {
      use: !props.disabledLoadMore,
      auto: false,
      loadMoreControlByHasNext: true,
      callback: (page, mescroll) => {
        loadMore(
          (pageDataLength, hasNext) => {
            console.log("hasNext", hasNext);
            mescroll.endSuccess(pageDataLength ?? 20, hasNext);
          },
          () => {
            mescroll.endErr();
          }
        );
      },
      loadFull: {
        use: true,
        delay: 500,
        maxNum: 10,
      },
      lazyLoad: {
        use: true, // 是否开启懒加载,默认false
        attr: "imgurl", // 网络地址的属性名 (图片加载成功会移除该属性): <img imgurl='网络图  src='占位图''/>
        showClass: "mescroll-lazy-in", // 图片加载成功的显示动画: 渐变显示,参见mescroll.css
        delay: 500, // 列表滚动的过程中每500ms检查一次图片是否在可视区域,如果在可视区域则加载图片
        offset: 200, // 超出可视区域200px的图片仍可触发懒加载,目的是提前加载部分图片
      },
      noMoreSize: 5,
      htmlRefreshing: `
            <div
              class="scroll-load-box-refreshing scroll-load-box-refreshing-bottom flex-center"
            >
              <div class="slb-text">
                ${pullTextConfig.loadMoreText}
              </div>
            </div>
          `,
      htmlNodata: `
            <div
              class="scroll-load-box-refreshing scroll-load-box-refreshing-bottom flex-center"
            >
              <div class="slb-text">
                ${pullTextConfig.loadMoreNoDataText}
              </div>
            </div>
          `,
      htmlLoading: `<p class="upwarp-progress mescroll-rotate"></p><p class="upwarp-tip">${pullTextConfig.loadingText}</p>`, // 上拉加载中的布局
      htmlNodata: `<p class="upwarp-nodata">${pullTextConfig.loadMoreNoDataText}</p>`,
      noMoreSize: 5,
      toTop: {
        src: "",
        offset: 1000,
      },
      empty: {
        warpId: "xxid",
        icon: "",
        tip: "",
      },
      lazyLoad: {
        use: false,
        attr: "imgurl",
      },
    },
  };
};
const stateData = {
  mescroll: null,
};

const renderData = reactive({
  isRender: true,
  data: props.datas,
  refreshing: props.refreshing,
  loadingMore: props.loadingMore,
  isLoading: false,
  finished: false,
  _disabledRefresh: false,
  disabledRefresh: props.disabledRefresh,
  disabledLoadMore: props.disabledLoadMore,
  mescrollConfig: getMescrollConfig(),
});

const renderFinishedEmpty = computed(() => {
  return !renderData.datas?.length && renderData.finished;
});
const mescrollInit = (mescroll) => {
  stateData.mescroll = mescroll;
  if (props.initLoad) {
    state.mescroll?.triggerDownScroll();
  }
};

const refresh = (done, errordone) => {
  if (!renderData.isLoading) {
    renderData.isLoading = true;
    emits(
      "refresh",
      (datas, isFinished) => {
        renderData.finished = isFinished ?? false;
        renderData.refreshing = false;
        renderData.isLoading = false;
        done(datas?.length, !(isFinished ?? false));
      },
      (err) => {
        renderData.refreshing = false;
        renderData.isLoading = false;
        errordone();
      }
    );
  } else {
    done();
  }
};
const loadMore = (done, errordone) => {
  if (!renderData.isLoading) {
    renderData.isLoading = true;
    renderData.loadingMore = true;
    renderData._disabledRefresh = true;
    emits(
      "loadMore",
      (datas, isFinished) => {
        renderData.finished = isFinished ?? false;
        renderData.loadingMore = false;
        renderData.isLoading = false;
        renderData._disabledRefresh = false;
        done(datas?.length, !(isFinished ?? false));
      },
      (err) => {
        renderData.loadingMore = false;
        renderData.isLoading = false;
        renderData._disabledRefresh = false;
        errordone();
      }
    );
  } else {
    renderData.loadingMore = false;
  }
};

const rerender = () => {
  renderData.isRender = false;
  nextTick(() => {
    renderData.isRender = true;
  });
};
const onScroll = (e) => {
  emits("onScroll", e);
};

const init = () => {
  renderData.datas = props.datas;
  renderData.refreshing = props.refreshing;
  renderData.loadingMore = props.loadingMore;
  renderData.isLoading = props.isLoading;
  renderData.finished = props.finished;
};

watch(
  () => props.datas,
  (newVal, oldVal) => {
    renderData.datas = props.datas;
  }
);
watch(
  () => props.refreshing,
  (newVal, oldVal) => {
    renderData.refreshing = props.refreshing;
  }
);
watch(
  () => props.loadingMore,
  (newVal, oldVal) => {
    renderData.loadingMore = props.loadingMore;
  }
);
watch(
  () => props.isLoading,
  (newVal, oldVal) => {
    renderData.isLoading = props.isLoading;
  }
);
watch(
  () => props.finished,
  (newVal, oldVal) => {
    renderData.finished = props.finished;
  }
);
watch(
  () => props.disabledLoadMore,
  (newVal, oldVal) => {
    renderData.disabledLoadMore = props.disabledLoadMore;
  }
);
watch(
  () => props.disabledRefresh,
  (newVal, oldVal) => {
    renderData.disabledRefresh = props.disabledRefresh;
  }
);

watch(
  () => renderData.disabledLoadMore,
  (newVal, oldVal) => {
    emits("update:disabledLoadMore", renderData.disabledLoadMore);
  }
);

watch(
  () => renderData.disabledRefresh,
  (newVal, oldVal) => {
    emits("update:disabledRefresh", renderData.disabledRefresh);
  }
);

watch(
  () => renderData.refreshing,
  (newVal, oldVal) => {
    emits("update:refreshing", renderData.refreshing);
  }
);
watch(
  () => renderData.loadingMore,
  (newVal, oldVal) => {
    emits("update:loadingMore", renderData.loadingMore);
  }
);
watch(
  () => renderData.isLoading,
  (newVal, oldVal) => {
    emits("update:isLoading", renderData.isLoading);
  }
);
watch(
  () => renderData.finished,
  (newVal, oldVal) => {
    emits("update:finished", renderData.finished);
  }
);

watch(
  () => props.datas,
  (newVal, oldVal) => {
    if (props.datas?.length) {
      refMescroll.value?.mescroll?.lockUpScroll(false);
    } else {
      refMescroll.value?.mescroll?.lockUpScroll(true);
    }
  }
);

const resetMescrollConfig = () => {
  renderData.mescrollConfig = getMescrollConfig();
  rerender();
};

onMounted(() => {
  $$registLanguageChangeListener(resetMescrollConfig);
  init();

  console.log("$slots", $slots, $slots.customContent);
});

onUnmounted(() => {
  $$removeLanguageChangeListener(resetMescrollConfig);
});

defineExpose({
  initUpScroll() {
    return new Promise((resolve, reject) => {
      refMescroll.value?.mescroll?.setScrollTop(0);
      refMescroll.value?.mescroll?.initUpScroll();
      resolve();
    });
  },
  triggerUpScroll() {
    refMescroll.value?.mescroll?.triggerUpScroll();
  },
  checkIsFull() {
    return (
      refMescroll.value?.mescroll?.getBodyHeight() <=
      refMescroll.value?.mescroll?.getClientHeight()
    );
  },
});
</script>
<style lang="scss">
.scroll-load-box {
  .scroll-load-box-refreshing {
    display: flex;
    justify-content: center;
    .slb-text {
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.5);
    }

    .rotate-loading {
      height: 20px;
      width: 20px;
      background: url("@/assets/img/loading-mini-3x.png");
      background-size: 100% 100%;
      margin-right: 10px;
    }
    .slb-refreshing {
      height: 20px;
      width: 20px;
    }
  }
  .mescroll-upwarp {
    /*visibility: visible !important;
    display: block !important;*/
    .mescroll-rotate {
      background: url("@/assets/img/loading-mini-3x.png");
      background-size: 100% 100%;
      height: 20px;
      width: 20px;
      border: none;
      animation: animation-rotate-infinite 0.75s infinite normal linear;
    }
    .upwarp-progress {
      background: url("@/assets/img/loading-mini-3x.png");
      background-size: 100% 100%;
      height: 20px;
      width: 20px;
      border: none;
      animation: animation-rotate-infinite 0.75s infinite normal linear;
    }
    .upwarp-tip {
      font-family: HarmonyOS Sans SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.5);
      margin-right: 20px;
    }
  }

  &.scroll-load-box-show-empty {
    .mescroll-upwarp {
      display: none !important;
    }
  }
  .mescroll-downwarp .downwarp-tip,
  .mescroll-upwarp .upwarp-tip,
  .mescroll-upwarp .upwarp-nodata {
    display: inline-block;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
    vertical-align: middle;
  }
}
</style>
