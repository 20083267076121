import axiosIS from "@/api/net/axios.js";
import ApiConfig, { baseUrl } from "@/config/apiConfig.js";
import useUserDataStore from "@/store/userDataStore.js";
import { getObjectStorageFullUrl } from "@/lib/objectStorage/objectStorage.js";
import { isProd } from "@/utils/tools.js";


export default {
  parseAudioData(data = {}, url) {
    return axiosIS(
      `${baseUrl}/api/pgc/outer/song/save`,
      data,
      { method: "post" }
    );
  },
  separateSong(data = {}, url) {
    return axiosIS(
      `${baseUrl}/api/pgc/song/separate`,
      data,
      { method: "post" }
    );
  },
  getTranslateAudioData(data = {}, url) {
    return axiosIS(
      `${baseUrl}/api/pgc/outer/song/translate`,
      data,
      { method: "post" }
    );
    
  },
  translatedGenerate(data = {}, url) {
    return axiosIS(
      `${baseUrl}/api/pgc/feed/translate/generate`,
      data,
      { method: "post" }
    );
  }

};
